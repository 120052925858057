import React, { FC } from 'react';
import { FooterCopyright } from '../ui/footer/FooterCopyright';
import { FooterItem } from '../ui/footer/FooterItem';
import { FooterLinks } from '../ui/footer/FooterLinks';
import { Footer as FooterUI } from '../ui/layout/Footer';
type Props = {
	adUrl?: string;
	rulesUrl?: string;
	gdprUrl?: string;
};
export const Footer: FC<Props> = ({ adUrl, rulesUrl, gdprUrl }) => {
	return (
		<FooterUI>
			<FooterCopyright>© {new Date().getFullYear()} Active Radio a.s.</FooterCopyright>
			<FooterLinks>
				{adUrl && (
					<FooterItem href={adUrl} target="_blank" rel="noopener">
						Reklama
					</FooterItem>
				)}
				{rulesUrl && (
					<FooterItem href={rulesUrl} target="_blank" rel="noopener">
						Podmínky použití
					</FooterItem>
				)}
				<FooterItem href="javascript:Didomi.preferences.show()">
					Nastavení soukromí
				</FooterItem>
				{gdprUrl && (
					<FooterItem href={gdprUrl} target="_blank" rel="noopener">
						Zásady ochany osobních údajů
					</FooterItem>
				)}
			</FooterLinks>
		</FooterUI>
	);
};

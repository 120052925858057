import { FC } from "react";

type Props = {}

export const MainContentRow: FC<Props> = ({ children }) =>
{
	return (
		<div className="main__wrap">
			<div className="grid">
				{children}
			</div>
		</div>
	)
}

import { PlayerState, Metadata, Channel } from '../../types/common';
import { TritonAsync } from './TritonAsync';
import { LaPlayer } from '../laplayer/LaPLayer';
import { useScript } from '../../hooks/useScript';

type Props = {
	urlCode: string;
	radioCode: string;
	defaultRadioCode: string;
	defaultChannel: string;
	onHistoryLoaded: (history: any) => void;
	onUpdateMetadata: (metadata: Metadata) => void;
	onChannelsLoaded: (channels: Channel[]) => void;
	isTritonPlayerActive: boolean;
	onAcPlayerStateChange: (state: PlayerState) => void;
	setAcPlayerState: (state: PlayerState) => void;
	acPlayerStateProposed: PlayerState;
	acPlayerState: PlayerState;
	tritonPlayerStateProposed: PlayerState;
	onTritonPlayerStateChange: (state: PlayerState) => void;
	progress?: number;
	playerVolume: number;
	onVolumeChange: (volume: number) => void;
	onVolumeMinusClick: () => void;
	onVolumePlusClick: () => void;
	metadata: Metadata | null;
	station: string;
	carodaUrl?: string;
};

export const PlayerEngineCombined = ({
	urlCode,
	radioCode,
	onHistoryLoaded,
	onUpdateMetadata,
	onChannelsLoaded,
	isTritonPlayerActive,
	onAcPlayerStateChange,
	setAcPlayerState,
	acPlayerStateProposed,
	acPlayerState,
	tritonPlayerStateProposed,
	onTritonPlayerStateChange,
	progress,
	playerVolume,
	onVolumeChange,
	onVolumeMinusClick,
	onVolumePlusClick,
	metadata,
	defaultRadioCode,
	defaultChannel,
	station,
	carodaUrl,
}: Props) => {
	const [acPlayerLoaded] = useScript(`https://s.actve.net/js/acplayer/acplayer.js`);
	const [carodaLoaded] = useScript(carodaUrl);
	return carodaLoaded || carodaUrl == null ? (
		<>
			{acPlayerLoaded && (
				<LaPlayer
					key="laplayer"
					urlCode={urlCode}
					radioCode={radioCode}
					defaultRadioCode={defaultRadioCode}
					defaultChannel={defaultChannel}
					onHistoryLoaded={onHistoryLoaded}
					updateMetadata={onUpdateMetadata}
					onChannelsLoaded={onChannelsLoaded}
					autoplay={false}
					noplay={isTritonPlayerActive ? true : false}
					{...(isTritonPlayerActive
						? {}
						: {
								onStateChange: onAcPlayerStateChange,
								setPlayerState: setAcPlayerState,
								proposedState: acPlayerStateProposed,
								playerState: acPlayerState,
								progress: progress,
								volume: playerVolume,
								onVolumeChange: onVolumeChange,
								onVolumeMinusClick: onVolumeMinusClick,
								onVolumePlusClick: onVolumePlusClick,
						  })}
				/>
			)}
			{isTritonPlayerActive && (
				<TritonAsync
					urlCode={urlCode}
					radioCode={radioCode}
					metadata={metadata}
					onChannelsLoaded={onChannelsLoaded}
					onStateChange={onTritonPlayerStateChange}
					proposedState={tritonPlayerStateProposed}
					progress={progress}
					volume={playerVolume}
					autoplay={false}
					onVolumeChange={onVolumeChange}
					onVolumeMinusClick={onVolumeMinusClick}
					onVolumePlusClick={onVolumePlusClick}
					station={station}
				/>
			)}
		</>
	) : null;
};

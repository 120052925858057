import { Rss } from '../../types/rss';
import { getRecentShowsItems } from '../getRecentShowsItems';

export const updateShowInLocalStorage = (show: string, feedShows: Rss) => {
	const showsLastItemIds = getRecentShowsItems('e2UniplayerShows');
	const lastItemId = feedShows.channel.item.filter(item => item.show === show).map(item => item.lastItemId)[0];

	if (lastItemId) {
		const newShowsLastItemIds = {
			...showsLastItemIds,
			[show]: lastItemId,
		};
		window.localStorage.setItem('e2UniplayerShows', JSON.stringify(newShowsLastItemIds));
		return newShowsLastItemIds;
	}

	return showsLastItemIds;
};

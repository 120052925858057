import Head from 'next/head'
import { Component } from 'react'

export class Gemius extends Component
{
	render()
	{
		return (
			<Head>
				<script src="//spir.hit.gemius.pl/xgemius.js" async defer key="script-gemius"></script>
			</Head>
		)
	}

	componentDidMount()
	{
		window.pp_gemius_identifier = this.props.gemiusId;
		window.pp_gemius_use_cmp = true; 

		gemius_pending('gemius_hit');
		gemius_pending('gemius_event');
		gemius_pending('pp_gemius_hit');
		gemius_pending('pp_gemius_event');
	}
}

function gemius_pending(i) {
	window[i] = window[i] || function () { var x = window[i + '_pdata'] = window[i + '_pdata'] || []; x[x.length] = arguments; };
}


import { FC } from "react";

export const RecordPlayerLoader: FC = () =>
{
	return (
		<div style={{
			width: '100%',
			height: 0,
			paddingTop: '56.17%',
			background: '#fff',
		}}>
		</div>
	)
}

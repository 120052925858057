import React, { Component } from 'react'
import { PlayerState } from '../../types/common';
// @ts-ignore
import makeTrashable from 'trashable';

type Props = {
	src: string,
	poster?: string,
	onEnded?: () => void,
	autoplay?: boolean,
	volume?: number,
	playerPromiseRef?: (x: Promise<any>) => void,
	onStateChange?: (state: PlayerState) => void,
	onVolumeChange?: (volume: number) => void,
	renderLoader?: () => React.ReactNode,
	state: PlayerState,
}

type State = {
	loaded: boolean,
}

type VolumeChangeEvent = {
	type: 'volumeChange',
	value: {
		muted: boolean,
		volume: number,
	}
}

export class CarodaPlayer extends Component<Props, State>
{
	el = React.createRef<HTMLDivElement>()

	player: any = null

	playerPromise?: Promise<any> & { trash: () => void }

	static defaultProps = {
		autoplay: false,
	}

	state = {
		loaded: false,
	}

	render()
	{
		return (
			<div ref={this.el} key="caroda-element">
				{
					!this.state.loaded && this.props.renderLoader && this.props.renderLoader()
				}
			</div>
		)
	}

	componentDidMount()
	{
		const playerPromise = this.initPlayer()
		if(this.props.playerPromiseRef) this.props.playerPromiseRef(playerPromise)
	}

	componentDidUpdate(prevProps: Props)
	{
		if(this.player != null && this.props.src !== prevProps.src) {
			this.destroyPlayer()
			const playerPromise = this.initPlayer()
			if(this.props.playerPromiseRef) this.props.playerPromiseRef(playerPromise)
		}

		if(this.player != null && this.props.volume !== prevProps.volume && this.props.volume != null) {
			if(this.props.volume === 0) {
				this.player.mute();
			}
			else {
				this.player.unmute();
				this.player.volume(this.props.volume / 100);
			}
		}
	}

	componentWillUnmount()
	{
		this.playerPromise && this.playerPromise.trash();
		this.destroyPlayer()
	}

	async initPlayer()
	{
		try {
			this.playerPromise = makeTrashable(initCarodaPlayer({
				element: this.el.current,
				config: {
					sourceUrl: this.props.src,
					...(
						this.props.poster ? {
							poster: this.props.poster
						} : {}
					),
					muted: false,
					// autoplay: canAutoplayAudio,
					// gemius: {
					// 	accountId - gemius specific
					// 	programId - gemius specific
					// 	programName - title of asset
					// 	typology - category
					// }
				}
			}));

			this.player = await this.playerPromise;

			window.activeCarodaPlayer = this.player;
			window.adoR.reload({}, true); // reload banner positions

			if(this.props.volume != null) {
				this.player.volume(this.props.volume / 100)
			}

			this.setState({loaded: true});

			// eventName must be one of tracking, error, play, pause, seek, complete, resume, adBreakStart, toggleFullscreen, playerConstructed
			if(this.player) {
				this.player.on('play', this.onPlay)
				this.player.on('resume', this.onPlay)
				this.player.on('adBreakStart', this.onAdBreakStart)
				this.player.on('adBreakStop', this.onAdBreakStop)
				this.player.on('volumeChange', this.onVolumeChange)
				this.player.on('pause', this.onPause)
				this.player.on('ended', this.onEnded)
				this.player.on('error', this.onError)
			}

			// if(this.player && canAutoplayAudio) this.player.play();

			return this.player;
		}
		catch(e) {
			return null;
		}
	}

	destroyPlayer()
	{
		if(this.player != null) {
			this.player.off('play', this.onPlay)
			this.player.off('resume', this.onPlay)
			this.player.off('adBreakStart', this.onAdBreakStart)
			this.player.off('adBreakStop', this.onAdBreakStop)
			this.player.off('volumeChange', this.onVolumeChange)
			this.player.off('pause', this.onPause)
			this.player.off('ended', this.onEnded)
			this.player.off('error', this.onError)
			this.player.destroy()
			this.player = null
		}
		this.setState({loaded: false});
	}

	onError = (e: any) =>
	{
		console.error(e)
	}

	onPlay = () =>
	{
		if(this.props.onStateChange) {
			this.props.onStateChange(PlayerState.Playing);
		}
	}

	onPause = () =>
	{
		if(this.props.onStateChange) {
			this.props.onStateChange(PlayerState.Stopped);
		}
	}

	onEnded = () =>
	{
		if(this.props.onStateChange) {
			this.props.onStateChange(PlayerState.Stopped);
		}
		if(this.props.onEnded) {
			this.props.onEnded();
		}
	}

	onAdBreakStart = () =>
	{
		if(this.props.onStateChange) {
			this.props.onStateChange(PlayerState.AdBreakStarted);
		}
	}

	onAdBreakStop = () =>
	{
		if(this.props.onStateChange) {
			this.props.onStateChange(PlayerState.Playing);
		}
	}

	onVolumeChange = (e: VolumeChangeEvent) =>
	{
		if(this.props.onVolumeChange) {
			this.props.onVolumeChange(e.value.volume)
		}
	}
}

type InitProps = {
	element: HTMLElement | null,
	config: any,
}

const initCarodaPlayer = async ({element, config}: InitProps) =>
{
	// @ts-ignore
	const { default: canAutoplay } = await import('can-autoplay');
	const { result: canAutoplayAudio } = await canAutoplay.audio();

	const carodaConfig = {
		...(config || {}),
		autoplay: canAutoplayAudio,
	}
	return window.loadCarodaPlayer(element, carodaConfig);
}
import React, { FC } from "react";

type Props = {}

export const PageHolderMain: FC<Props> = ({ children }) =>
{
	return (
		<div className="page-holder__main branding-wrapper">
			{children}
		</div>
	)
}

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const PauseIcon: FC<Props> = ({ width = 14, height = 22 }) =>
{
	return (
		<IconWrapper id="pause" width={width} height={height} nativeWidth={14} nativeHeight={22}>
			<path d="M0 0h2v22H0V0zm12 0h2v22h-2V0z"/>
		</IconWrapper>
	);
};

import React, { FC } from "react";
import { AngleRightIcon } from '../icons/AngleRightIcon'
import { truncate } from '../../../utils/truncate'

type Props = {
	href?: string,
	title?: string,
	imgSrc?: string,
}

export const InlineArticle: FC<Props> = ({ href, title, imgSrc }) =>
{
	return (
		<a href={href} target="_blank" className="b-inline-article">
			{
				imgSrc != null &&
					<figure className="b-inline-article__img">
						<img src={imgSrc} alt=""/>
					</figure>
			}

			<div className="b-inline-article__content">
				{
					title != null &&
						<p className="b-inline-article__title">{truncate(title, 70)}</p>
				}
				<span className="b-inline-article__icon">
					<AngleRightIcon />
				</span>
			</div>
		</a>
	)
}

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const FacebookIcon: FC<Props> = ({ width = 28, height = 28 }) =>
{
	return (
		<IconWrapper id="facebook" width={width} height={height} nativeWidth={28} nativeHeight={28}>
			<path d="M17.993 7.101c-.7-.07-1.403-.104-2.107-.1-2.092 0-3.526 1.161-3.526 3.289v1.834H10v2.49h2.36V21h2.833v-6.386h2.35l.364-2.49h-2.711v-1.59c0-.715.212-1.212 1.35-1.212H18v-2.22h-.007z"/>
		</IconWrapper>
	);
};

import { Rss } from '../../types/rss';
import { groupRssItemsByDate } from '../../utils/groupRssItemsByDate';
import { FormattedRelativeDays } from '../service/FormattedRelativeDays';
import { RecordItem } from '../ui/records/RecordItem';
import { MediaWrapDayGroup } from '../ui/mediaWrap/MediaWrapDayGroup';
import { MediaWrapGroup } from '../ui/mediaWrap/MediaWrapGroup';
import { MediaWrapSubheading } from '../ui/mediaWrap/MediaWrapSubheading';
import { FC } from 'react';
import { createChannelParam } from '../../utils/createChannelParam';
import { getShowName } from '../../utils/getShowName';
import { PLAYLIST_ITEMS_COUNT } from '../../constants';
import Link from 'next/link';
import { filterNullProps } from '../../utils/filterNullProps';

type Props = {
	rss: Rss;
	archive?: string;
	show?: string;
	channel?: string;
	feedShows: Rss | null;
};

export const RecordsList: FC<Props> = ({ rss, archive, show, channel, feedShows }) => {
	const postsByDate = rss != null ? groupRssItemsByDate(rss.channel.item || [], PLAYLIST_ITEMS_COUNT) : [];

	const postsByDateWithFormattedDate = postsByDate.map(item => ({
		pubDate: item.pubDate,
		formattedDate: <FormattedRelativeDays value={new Date(item.pubDate)} />,
		items: item.items,
	}));

	return (
		// Wrapping to fragment due to Typescript issue https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
		<>
			{postsByDateWithFormattedDate.map(dateGroup => (
				<MediaWrapGroup key={dateGroup.pubDate}>
					<MediaWrapSubheading>{dateGroup.formattedDate}</MediaWrapSubheading>
					<MediaWrapDayGroup>
						{dateGroup.items.map((item, i) => (
							<Link
								href={{
									pathname: '/',
									query: filterNullProps({
										play: item.idEpisode,
										archive,
										show,
										...createChannelParam(channel),
									}),
								}}
								passHref
								key={item.guid != null && item.guid._ != null ? item.guid._ : i}
							>
								<RecordItem item={item} showName={getShowName(feedShows)(item.show)} />
							</Link>
						))}
					</MediaWrapDayGroup>
				</MediaWrapGroup>
			))}
		</>
	);
};

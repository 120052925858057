declare global {
	interface Window {
		acpcb: any,
		acp: any,
		player: any,
		jQuery: any,
		Colpirio: any,
		TDSdk: any,
		tritonPlayer: any,
		loadCarodaPlayer: (x: any, y: any) => Promise<any>,
		activeCarodaPlayer: any,
	}
}

export type Channel = {
	masterStation: string,
	radioCode: string,
	urlCode: string,
	saCode: string | null,
	name: string,
	icon: string,
	icon200: string,
	wideIcon: string,
	hasNext: boolean
}

export type Channels = {
	channels: Channel[]
}

export type HistoryItem = {
	cover: string | null,
	favorite: string | null,
	mediaId: string | null,
	movieUrl: string | null,
	songAlbum: string | null,
	songArtist: string | null,
	songDuration: string | null,
	songStart: string | null,
	songTitle: string | null,
	yrArtistNormalizedName: string | null,
	yrSongId: string | null,
}

export type ChartItem = {
	id: number,
	cover: string | null,
	songArtist: string | null,
	songTitle: string | null,
	rank: number | null,
}

export type Metadata = {
	artist?: string | null,
	title?: string | null,
	album?: string | null,
	moderatorName?: string | null,
	showName?: string | null,
	status?: string | null,
	radioCode?: string | null,
	tritonId?: string | null,
	name?: string | null,
	mediaId?: string | null,
	yrSongId?: number | null,
	starttime?: number | null,
	duration?: number | null,
	cover?: string | null,
	streamHi?: string | null,
	moderatorURL?: string | null,
	moderatorMotiveURL?: string | null,
	partnerName?: string | null,
	partnerURL?: string | null,
	showURL?: string | null,
	radioDescription?: string | null,
	movieUrl?: string | null,
	nextrefresh?: number | null,
	moderatorSmallMotiveURL?: string | null,
	moderatorMiniMotiveURL?: string | null,
	history?: HistoryItem[],
	showStart?: string | null,
	showEnd?: string | null,
}


export enum PlayerState {
	Waiting = 'WAITING',
	Playing = 'PLAYING',
	Stopped = 'STOPPED',
	AdBreakStarted = 'AD_BREAK_STARTED',
}

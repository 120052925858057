import React, { FC } from "react";
import classNames from 'classnames';

type Props = {
	isHidden?: boolean,
	hasBorder?: boolean,
}

export const MediaWrapHeading: FC<Props> = ({ children, isHidden, hasBorder }) => {

	const classes = classNames(
		'b-media-wrap__title',
		hasBorder ? 'b-media-wrap__title--bd' : '',
		isHidden ? 'u-vhide' : '',
	)
	return (
		<h2 className={classes}>
			{hasBorder ? <span>{children}</span> : children}
		</h2>
	)
}

export const SITE_NAME = 'onair';

export const VOTE_UP = 1;
export const VOTE_DOWN = 0;

export const DEFAULT_CHANNEL_FREKVENCE1 = 'fm';
export const DEFAULT_RADIO_CODE_FREKVENCE1 = 'frekvence1';

export const VOLUME_INCREMENT = 5;

export const defaultBrandingAdDataFrekvence1 = {
	paddingTop: 140,
	color: 'aeedff',
	top: `/static/images/bg/default.jpg`,
	second: null,
	topHeight: 1047,
	backgroundSize: '100% auto',
};

export const URL_PROGRAM_FREKVENCE1 = 'https://www.frekvence1.cz/program';
export const URL_ARCHIVE_FREKVENCE1 = 'https://www.frekvence1.cz/audio-video?porad=';

export const AD_UNITS_FREKVENCE1 = {
	INTERSCROLLER: 'evropa2.cz_Interscroller',
};

export const AD_RULES_URL = 'https://www.activegroup.cz/reklama';
export const GDPR_URL = 'https://www.frekvence1.cz/privacy-policy';

import { FC } from "react";

type Props = {}

export const ShowOnTabletDown: FC<Props> = ({ children }) => {
	return (
		<div className="u-hide@md">
			{children}
		</div>
	)
}

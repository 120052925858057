import { ReactNode, FC, useCallback, useState } from 'react';
import { Transition } from 'react-transition-group';

type Props = {
	heading?: ReactNode;
	showName: ReactNode;
	showTime?: ReactNode;
	imgSrc?: string[];
	moderatorName?: string;
	programUrl?: string;
};

export const Following: FC<Props> = ({ heading, showName, showTime, imgSrc, moderatorName, programUrl }) => {
	const [mouseInside, setMouseInside] = useState(false);

	const onMouseEnter = useCallback(() => {
		setMouseInside(true);
	}, []);

	const onMouseLeave = useCallback(() => {
		setMouseInside(false);
	}, []);

	const duration = 300;

	const defaultStyle = {
		transition: `opacity ${duration}ms`,
		opacity: 0,
	};

	const transitionStyles = {
		entering: { opacity: 1 },
		entered: { opacity: 1 },
		exiting: { opacity: 0 },
		exited: { opacity: 0 },
		unmounted: {},
	};

	return (
		<div className="b-following" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			{heading != null && (
				<p className="b-following__heading">
					{programUrl ? (
						<a className="u-color-red" href={programUrl} target="_blank" rel="noopener">
							{heading}
						</a>
					) : (
						<span className="u-color-red">{heading}</span>
					)}
					{
						<Transition
							in={moderatorName != null && moderatorName != '' && mouseInside}
							timeout={duration}
							mountOnEnter
							unmountOnExit
							appear
						>
							{(state) => (
								<span
									className="b-following__moderator"
									style={{
										...defaultStyle,
										...transitionStyles[state],
									}}
								>
									{' '}
									- {moderatorName}
								</span>
							)}
						</Transition>
					}
				</p>
			)}
			{showName != null && (
				<h2 className="b-following__title">
					{programUrl ? (
						<a className="u-color-black" href={programUrl} target="_blank" rel="noopener">
							{showName}
						</a>
					) : (
						<span className="u-color-black">{showName}</span>
					)}
				</h2>
			)}
			{showTime != null && <p className="b-following__meta">{showTime}</p>}
			{imgSrc && (
				<div
					style={{
						display: 'flex',
					}}
				>
					{imgSrc.filter(src => !!src).map((src) => (
						<figure
							className="b-following__img"
							style={{
								backgroundImage: `url(${src})`,
								marginRight: 8,
							}}
						>
							<img src={src} alt="" />
						</figure>
					))}
				</div>
			)}
		</div>
	);
};

import * as React from 'react';
import { Component } from 'react';
import classNames from 'classnames';

type Props = {
	variant?: 'link'
	[x: string]: any
}

export class AnchorButton extends Component<Props>
{
	render()
	{
		const { children, variant, ...restProps } = this.props;
		const classes = classNames(
			'btn',
			variant ? `btn--${variant}` : '',
		)

		return (
			<a className={classes} {...restProps}>
				<span className="btn__text">{children}</span>
			</a>
		);
	}
}

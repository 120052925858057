import { ReactNode, Component, forwardRef } from "react";
import classNames from 'classnames';
import { hasBranding } from '../../../utils/selectors';
import { StateConsumer } from '../../ads/StateProvider';

type Props = {
	hasSolidBg?: boolean,
	children: ReactNode,
}

type PropsWithState = Props & {
	hasBranding: boolean,
}

type PropsWithRef = Props & {
	forwardedRef: any,
}

export const Main = forwardRef((props: Props, ref) => {
	return <MainWithRef {...props} forwardedRef={ref} />;
});

export class MainWithRef extends Component<PropsWithRef>
{
	render()
	{
		const { forwardedRef, ...props } = this.props;
		return (
			<StateConsumer>
				{
					(state) =>
						<MainWithState {...props} hasBranding={hasBranding(state)} ref={forwardedRef} />
				}
			</StateConsumer>
		)
	}
}

export const MainWithState = forwardRef<HTMLElement, PropsWithState>(({ children, hasSolidBg, hasBranding }, ref) =>
{
	const classes = classNames(
		'main',
		hasSolidBg && !hasBranding ? 'main--solid-bg' : '',
	);

	return (
		<main role="main" id="main" className={classes} ref={ref}>
			{children}
		</main>
	)
});

import { SubmenuChannels } from "../ui/submenu/SubmenuChannels";
import { ChannelList } from "../ui/channels/ChannelList";
import { DEFAULT_CHANNEL } from "../../constants";
import { Channel } from "../../types/common";
import React, { FC } from "react";
import { ChannelItem } from "../ui/channels/ChannelItem";
import Link from "next/link";
import { filterNullProps } from "../../utils/filterNullProps";

type Props = {
	channels: Channel[] | null,
	defaultChannel?: string,
	onChannelsToggle: () => void,
	onChannelsClose: () => void,
	onChannelsOpen: () => void,
	activeChannelUrlCode: string
}

export const ChannelsMenu: FC<Props> = React.memo(({ channels, defaultChannel = DEFAULT_CHANNEL, onChannelsToggle, onChannelsOpen, onChannelsClose, activeChannelUrlCode }) =>
{
	return (
		<SubmenuChannels onHover={onChannelsOpen} onBlur={onChannelsClose}>
			{
				channels &&
					<ChannelList
						srHeading="Kanály"
					>
						{
							channels.map(channel => (
								<Link
									href={{
										pathname: '/',
										query: filterNullProps({
											...(
												channel.urlCode === defaultChannel ?
													{}
													:
													{
														channel: channel.urlCode,
													}
											)
										})
									}}
									passHref
									shallow
									scroll={false}
									key={channel.urlCode}
									>
									<ChannelItem
										onClick={onChannelsToggle}
										isActive={activeChannelUrlCode === channel.urlCode}
										channel={channel}
									/>
								</Link>
							))
						}
					</ChannelList>
			}
		</SubmenuChannels>
	)
})
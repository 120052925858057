import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const AngleRightIcon: FC<Props> = ({ width = 24, height = 24 }) =>
{
	return (
		<IconWrapper id="angle-d" width={width} height={height} nativeWidth={24} nativeHeight={24}>
			<path d="M6 3.333L7.412 2 18 12 7.412 22 6 20.667 15.176 12z"/>
		</IconWrapper>
	);
};

import React, { FC } from "react";

type Props = {
	srHeading?: string,
}

export const ChannelList: FC<Props> = ({ srHeading, children }) =>
{
	return (
		<div className="c-channel-list">
			{
				srHeading &&
					<h2 className="u-vhide">{srHeading}</h2>
			}
			{
				React.Children.count(children) > 0 &&
					<ul className="c-channel-list__list">
						{
							React.Children.map(children, child => (
								<li className="c-channel-list__item">
									{child}
								</li>
							))
						}
					</ul>
			}
		</div>
	)
}

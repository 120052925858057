import { FC } from "react";

type Props = {}

export const ShowOnTabletUp: FC<Props> = ({ children }) => {
	return (
		<div className="u-hide u-show@md">
			{children}
		</div>
	)
}

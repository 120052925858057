import { FC } from "react";

type Props = {}

export const FooterLinks: FC<Props> = ({ children }) =>
{
	return (
		<p className="footer__items">
			{children}
		</p>
	)
}

import { RssItem } from "../types/rss";

type Result =  {[x: string]: Array<RssItem>};

export const groupRssItemsByDate = (postItems: Array<RssItem>, maxItemsCount: number): Groups =>
{
	if(postItems == null) return [];
	return dateGroupsToArray(postItems.reduce((res: Result, post, i) => {
		if(post.pubDate != null) {
			const date = new Date(post.pubDate);
			date.setHours(12);
			date.setMinutes(0);
			date.setSeconds(0);
			date.setMilliseconds(0);

			const key = date.toISOString()

			// Only add new items until the list is roughly of the same length as playlist:
			if(i + Object.keys(res).length <= maxItemsCount) {
				if(!(key in res)) res[key] = [];
				res[key].push(post);
			}
		}
		return res;
	}, {}));
}

type Groups = {
	pubDate: string,
	items: RssItem[]
}[]

export const dateGroupsToArray = (postsByDate: Result): Groups =>
{
	return Object.keys(postsByDate).map(key => ({
		pubDate: key,
		items: postsByDate[key],
	}))
}
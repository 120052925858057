import React, { Component } from 'react';
import NextHead from 'next/head';
import getConfig from 'next/config';
import { Gemius } from '../analytics/Gemius';
import { GoogleTagManager } from '../analytics/GoogleTagManager';
import { GoogleAnalytics } from '../analytics/GoogleAnalytics';
import { GemiusPlayer } from '../analytics/GemiusPlayer';
import { Netmonitor } from '../analytics/Netmonitor';
import { CustomDFPSProvider } from '../analytics/CustomDFPSProvider';

const { publicRuntimeConfig } = getConfig();

type Props = {
	isWindowLoaded: boolean;
	author?: string;
	appleAppId?: string;
	carodaUrl?: string;
};

export class BaseLayout extends Component<Props> {
	render() {
		const { children, isWindowLoaded, author, appleAppId, carodaUrl } = this.props;
		return (
			<CustomDFPSProvider dfpNetworkId={publicRuntimeConfig.env.DFP_NETWORK_ID}>
				<NextHead>
					<meta charSet="utf-8" key="meta-charset" />
					{author && <meta name="author" content={author} key="meta-author" />}
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=0"
						key="meta-viewport"
					/>
					{appleAppId && <meta name="apple-itunes-app" content={appleAppId} key="meta-app-id" />}
					{/* <link rel="manifest" href={`${CDN_URLS.favicon}/site.webmanifest`} /> */}
					<style key="style-ads">{`
						.ads{text-align:center;display:block;}
						.ads-in{display:inline-block;}
						.ads-in.adFull::before {content: "reklama";font-family:arial;font-size:10px;color:#000;display:block;height:11px;text-align:right;}
						@media (min-width: 1000px) {
							#Leaderboard-1 { min-height: 200px; }
						}
						@media (max-width: 999px) {
							.ads{ height: 0px; }
						}
						`}</style>
					<link
						rel="stylesheet"
						href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&subset=latin-ext&display=swap"
						key="fonts"
					/>
					{typeof window !== 'undefined' && carodaUrl != null && (
						<script src={carodaUrl} crossOrigin="anonymous" defer key="caroda"></script>
					)}
				</NextHead>
				{isWindowLoaded && (
					<>
						{publicRuntimeConfig.env.GTM_ID && <GoogleTagManager gtmId={publicRuntimeConfig.env.GTM_ID} />}
						{publicRuntimeConfig.env.GA_ID && <GoogleAnalytics gaId={publicRuntimeConfig.env.GA_ID} />}
						{publicRuntimeConfig.env.GEMIUS_ID && <Gemius gemiusId={publicRuntimeConfig.env.GEMIUS_ID} />}
						{publicRuntimeConfig.env.GEMIUS_PLAYER_NAME && publicRuntimeConfig.env.GEMIUS_PLAYER_ID && (
							<GemiusPlayer
								gemiusPlayerName={publicRuntimeConfig.env.GEMIUS_PLAYER_NAME}
								gemiusPlayerId={publicRuntimeConfig.env.GEMIUS_PLAYER_ID}
							/>
						)}
						<Netmonitor />
					</>
				)}
				{children}
			</CustomDFPSProvider>
		);
	}

	handleScriptLoad() {
		//this.setState({ permutiveLoaded: true })
	}

	onBlur() {
		console.log('Tab is hidden');
		window.reloadInterval = active_player_config.radiobonton.reloadInterval * 1000 || 300000;
	}
	onFocus() {
		console.log('Tab is in viewport');
		if (window.interval != 0) {
			//adoR.reload({}, true);
		}
	}

	componentDidMount() {
		window.addEventListener('focus', this.onFocus);
		window.addEventListener('blur', this.onBlur);
		window.interval = 0;
		this.onFocus();
	}

	componentWillUnmount() {
		window.clearTimeout(window.interval);
		window.removeEventListener('focus', this.onFocus);
		window.removeEventListener('blur', this.onBlur);
	}
}

import { LazyImg } from '../common/LazyImg';
import { FC } from 'react';

type Props = {
	thumbnail?: string;
};

export const MediaWrapImgPlay: FC<Props> = ({ thumbnail }) => (
	<figure className="b-media-item__img">
		<LazyImg src={thumbnail} width={42} height={42} />
	</figure>
);

import * as React from 'react';
import { CarodaPlayer } from './CarodaPlayer';
import { RssItem } from '../../types/rss';
import { pathOr } from 'ramda';
import { RecordPlayer } from '../ui/player/RecordPlayer';
import { SocialShare } from '../ui/socialShare/SocialShare';
import { SocialShareBtn } from '../ui/socialShare/SocialShareBtn';
import { BackToPlayer } from '../ui/common/BackToPlayer';
import { PlayerState } from '../../types/common';
import { makeRouterParams } from '../../utils/makeRouteParams';
import { DEFAULT_CHANNEL } from '../../constants';
import { RecordPlayerLoader } from '../ui/player/RecordPlayerLoader';
import Link from 'next/link';
import { filterNullProps } from '../../utils/filterNullProps';
import { useScript } from '../../hooks/useScript';

type Props = {
	archive?: string;
	show?: string;
	channel?: string;
	play?: string;
	playRecord?: RssItem;
	onStateChange?: (state: PlayerState) => void;
	state: PlayerState;
	volume?: number;
	siteUrl?: string;
	carodaUrl: string;
	onRecordEnd?: () => void;
};

export const RecordPlayerBox = (props: Props) => {
	const { archive, show, playRecord, state, volume, onStateChange, onRecordEnd, carodaUrl } = props;

	const url = pathOr(undefined, ['enclosure', '$', 'url'], playRecord);
	const poster = pathOr(undefined, ['media:content', '$', 'url'], playRecord);

	const shareUrl = typeof window === 'undefined' ? null : window.location.toString();

	const [carodaLoaded] = useScript(carodaUrl);

	return (
		<RecordPlayer
			heading="Záznam"
			player={
				url != null && carodaLoaded ? (
					<CarodaPlayer
						src={url}
						poster={poster}
						state={state}
						volume={volume}
						onStateChange={onStateChange}
						onEnded={onRecordEnd}
						renderLoader={renderLoader}
					/>
				) : null
			}
			sideContent={
				<React.Fragment>
					{shareUrl != null && (
						<SocialShare>
									<SocialShareBtn
										variant="facebook"
										url={shareUrl}
										srHeading="Sdílet na Facebook"
									/>
									<SocialShareBtn
										variant="twitter"
										url={shareUrl}
										srHeading="Sdílet na Twitter"
									/>
						</SocialShare>
					)}
					<Link
						href={{
							pathname: '/',
							query: filterNullProps(
								makeRouterParams({ archive, show }, { channel: DEFAULT_CHANNEL }) || {}
							),
						}}
						passHref
						shallow
					>
						<BackToPlayer>Zpět na živé vysílání</BackToPlayer>
					</Link>
				</React.Fragment>
			}
		/>
	);
};

const renderLoader = () => <RecordPlayerLoader />;

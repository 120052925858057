import { FC, Component, ReactNode } from "react";
import classNames from 'classnames';
import { HeaderChannelsToggler } from '../header/HeaderChannelsToggler';
import { HeaderLogo } from '../header/HeaderLogo';
import { hasBranding } from '../../../utils/selectors';
import { StateConsumer } from '../../ads/StateProvider';

export class Header extends Component<Props>
{
	render()
	{
		return (
			<StateConsumer>
				{
					(state) =>
						<HeaderWithState {...this.props} hasBranding={hasBranding(state)} />
				}
			</StateConsumer>
		)
	}
}

type Props = {
	onMenuClick?: () => void,
	onChannelsTogglerClick?: () => void,
	onChannelsOpen?: () => void,
	onChannelsClose?: () => void,
	slotLeft?: ReactNode,
	slotCenter?: ReactNode,
	slotRight?: ReactNode,
	slotTabs?: ReactNode,
	isChannelsTogglerOpened?: boolean,
	hasSolidBg?: boolean,
	hasVideoInside?: boolean,
	hasBranding?: boolean,
	logo?: ReactNode,
}

export const HeaderWithState: FC<Props> = ({ onChannelsTogglerClick, onChannelsOpen, onChannelsClose, slotLeft, slotCenter, slotRight, slotTabs, isChannelsTogglerOpened, hasSolidBg, hasVideoInside, hasBranding, logo }) => {
	const classes = classNames(
		'header',
		hasSolidBg && !hasBranding ? 'header--solid-bg' : '',
		hasVideoInside ? 'header--video' : '',
	);

	return (
		<header role="banner" className={classes}>
			<div className="header__wrap">
				{/* TODO: nezobrazovat dokud nebude pripraveno <a className="header__menu" onClick={onMenuClick}>
					<MenuIcon />
					<span className="u-vhide">Menu</span>
				</a>*/}

				<div className="header__tabs">
					{slotTabs}
				</div>


				<div className="header__column header__column--l">
					<div className="header__channels-toggler">
						{typeof onChannelsTogglerClick === 'function' ? (
							<HeaderChannelsToggler
								isToggled={isChannelsTogglerOpened}
								onClick={onChannelsTogglerClick}
								onHover={onChannelsOpen}
								onBlur={onChannelsClose}
								logo={logo}
							/>
						) : <HeaderLogo logo={logo} />}
					</div>

					{slotLeft}
				</div>

				{
					slotCenter != null &&
						<div className="header__column header__column--c">
							{slotCenter}
							<div className="u-clearfix"></div>
						</div>
				}

				{
					slotRight != null &&
						<div className="header__column header__column--r">{slotRight}</div>
				}
			</div>
		</header>
	)
}

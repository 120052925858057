import { useState, useCallback } from 'react';
import { VOLUME_INCREMENT } from '../constants';

export const useVolume = (defaultVolume: number = 100) => {
	const [volume, setVolume] = useState<number>(defaultVolume);

	const decrementVolume = useCallback(() => {
		setVolume(volume => Math.max(volume - VOLUME_INCREMENT, 0));
	}, []);

	const incrementVolume = useCallback(() => {
		setVolume(volume => Math.min(volume + VOLUME_INCREMENT, 100));
	}, []);

	return { volume, setVolume, incrementVolume, decrementVolume };
};

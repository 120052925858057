// @ts-ignore
import tzo from 'timezone'
// @ts-ignore
import eu from 'timezone/Europe/Prague'

export type DateProps = {
	year: number,
	month: number,
	day: number,
	weekDay: number,
	hours: number,
	minutes: number,
	seconds: number
}

export type TimeProps = {
	hours: number,
	minutes: number
}

const tz = tzo(eu)

export const formatDateToPlaylist = (): string =>
{
	return ''
}

export const getWeekStartTimestamp = (firstWeekDay: number, date: Date | number) =>
{
	const {weekDay} = getLocalDateProps(date)
	const weekDayDelta = (weekDay - firstWeekDay + 7) % 7
	const {year: weekStartYear, month: weekStartMonth, day: weekStartDay} = getLocalDateProps(tz(date, `-${weekDayDelta} days`))

	return tz(`${weekStartYear}-${zeroPad2(weekStartMonth + 1)}-${zeroPad2(weekStartDay)}`, getTimeZone())
}

export const getMonthStartTimestamp = (date: Date | number): number =>
{
	const {year, month} = getLocalDateProps(date)
	return tz(`${year}-${zeroPad2(month + 1)}-${zeroPad2(1)}`, getTimeZone())
}

// export const getTimeZone = (): string => timeZone
export const getTimeZone = (): string => 'Europe/Prague'

export const zeroPad2 = (num: number): string => num >= 10 ? `${num}` : `0${num}`

export const getLocalDay = (date: Date | number): number => parseInt(tz(date, getTimeZone(), '%-d'), 10)

export const getLocalTimestamp = (year: number = 1970, month: number =0, day: number = 1, hours: number = 0, minutes: number = 0, seconds: number = 0): number =>
{
	return tz(`${year}-${zeroPad2(month + 1)}-${zeroPad2(day)} ${zeroPad2(hours)}:${zeroPad2(minutes)}:${zeroPad2(seconds)}`, getTimeZone())
}

export const getLocalDate = (year: number = 1970, month: number = 0, day: number = 1, hours: number = 0, minutes: number = 0, seconds: number = 0): Date =>
{
	return new Date(getLocalTimestamp(year, month, day, hours, minutes, seconds))
}

export const getLocalDateFromDateProps = (dateProps: DateProps): Date =>
{
	return getLocalDate(dateProps.year, dateProps.month, dateProps.day, dateProps.hours, dateProps.minutes, dateProps.seconds);
}

export const getLocalDateProps = (date: Date | number): DateProps =>
{
	const [year, month, day, weekDay, hours, minutes, seconds] = tz(date, getTimeZone(), "%Y %-m %-d %w %H %M %S").split(' ').map((v: string) => parseInt(v, 10))

	return  {
		year, month: month - 1, day, weekDay, hours, minutes, seconds
	}
}

export const parseLocalDate = (date: string): Date =>
{
	return new Date(tz(date, getTimeZone()))
}

export const formatDate = (date: Date | number, format: string) => tz(date, getTimeZone(), format)

export const offsetTimestamp = (date: string | number | Date, ...args: Array<string>): number => tz(date, getTimeZone(), ...args)

export const signedNumber = (value: number): string => `${value >= 0 ? '+' : '-'}${Math.abs(value)}`

export const getNextSameWeekDay = (date: Date, weekDayArg: number) =>
{
	const weekStartDay = 1
	const weekDayArgNorm = (weekDayArg + 7 + weekStartDay) % 7
	const { weekDay } = getLocalDateProps(date)
	const dayOffset = (weekDayArgNorm + 7 - weekDay) % 7

	return getLocalDateProps(offsetTimestamp(date, `${signedNumber(dayOffset)} days`))
}

export const datePropsToTimeProps = (dateProps: DateProps): TimeProps => ({
	hours: dateProps.hours,
	minutes: dateProps.minutes,
})

export const parseIsoDateFromUrl = (dateString: string): string | null =>
{
	try {
		if(dateString) {
			return new Date(dateString).toISOString()
		}
		else return null
	}
	catch(e) {}
	return null
}

export const timePropsToDate = (today: Date, timeProps: TimeProps) =>
{
	return getLocalDateFromDateProps({...getLocalDateProps(today), ...timeProps});
}
import { FC } from "react";

type Props = {
	hideOnMobile?: boolean,
}

export const MainContentColumn: FC<Props> = ({ children, hideOnMobile = false }) => {
	return (
		<div className={['grid__cell size--6-12@md', hideOnMobile && 'u-hide u-show@md'].filter(Boolean).join(' ')}>
			{children}
		</div>
	)
}

import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
	isOpened?: boolean,
}

export const PageHolderSidebar: FC<Props> = ({ isOpened, children }) => {
	const classes = classNames(
		'page-holder__sidebar',
		isOpened ? 'is-opened' : '',
	)

	return (
		<div className={classes}>
			{children}
		</div>
	)
}

import classNames from 'classnames';
import React, { forwardRef, SyntheticEvent, ReactNode } from "react";

type Props = {
	isActive?: boolean,
	href?: string,
	titleSize?: boolean,
	onClick?: (e: SyntheticEvent) => void,
	children?: ReactNode,
}

export const TabsNavItem = forwardRef<HTMLAnchorElement, Props>(({ children, isActive = false, href, titleSize, onClick }, ref) => {
	const classes = classNames(
		'm-tabs__link',
		titleSize ? 'h2' : '',
		isActive ? 'is-active' : '',
	)

	return (
		<a href={href} onClick={onClick} className={classes} ref={ref}>
			{children}
		</a>
	)
})


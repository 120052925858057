import React, { FC } from "react";

type Props = {}

export const MediaWrapSubheading: FC<Props> = ({ children }) =>
{
	return (
		<h4 className="b-media-wrap__subtitle h6">{children}</h4>
	)
}

import { Channel } from "../../../types/common";
import { SyntheticEvent, forwardRef } from "react";
import classNames from 'classnames';

type Props = {
	channel: Channel,
	isActive?: boolean,
	href?: string,
	onClick?: (e: SyntheticEvent) => void,
}

export const ChannelItem = forwardRef<HTMLAnchorElement, Props>(({ channel, isActive = false, href, onClick }, ref) =>
{
	const classes = classNames(
		'b-channel-item',
		isActive ? 'is-active' : ''
 	)

	return (
		channel != null ?
			<a href={href} className={classes} onClick={onClick} ref={ref}>
				<figure
					className="b-channel-item__img"
					style={{
						backgroundImage: channel.icon ? `url(${channel.icon})` : undefined
					}}
				/>
				<div className="b-channel-item__content">
					<p className="b-channel-item__title">{channel.name}</p>
				</div>
			</a>
			:
			null
	)
})

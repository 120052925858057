import * as React from 'react';
import { Component } from 'react';
import { Script } from './Script';

type Props = {
	src: string,
	render?: () => React.ReactNode,
	renderLoader?: () => React.ReactNode,
	onLoad?: () => void,
	onError?: () => void,
}

type State = {
	loaded: boolean,
}

export class ScriptAsync extends Component<Props, State>
{
	state: State = {
		loaded: false,
	}

	render()
	{
		const { src, render, renderLoader } = this.props;

		return (
			<>
				<Script src={src} onLoad={this.onLoad} onError={this.onError} async></Script>
				{
					this.state.loaded === true && render != null ?
						render()
						:
						(
							renderLoader && renderLoader()
						)
				}
			</>
		);
	}

	onLoad = () =>
	{
		this.setState({ loaded: true })
		if (this.props.onLoad) {
			this.props.onLoad();
		}
	}

	onError = () =>
	{
		if (this.props.onError) {
			this.props.onError();
		}
	}
}

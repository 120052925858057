import React, { FC, Fragment } from 'react';

type Props = {};

export const PlayList: FC<Props> = ({ children }) => {
	const childrenMainBatch = React.Children.toArray(children);
	const childrenSecondBatch = childrenMainBatch.splice(7);
	const batches = childrenSecondBatch.length ? [childrenMainBatch, childrenSecondBatch] : [childrenMainBatch];

	return (
		<div className="c-media-list c-media-list--playlist">
			{React.Children.count(children) > 0
				? batches.map((_batch, index) => (
						<Fragment key={index}>
							<ul className={`c-media-list__list${batches.length > 1 && index === 0 ? ' u-mb-0' : ''}`}>
								{batches[index].map((child, i) => (
									<li className="c-media-list__item" key={i}>
										{child}
									</li>
								))}
							</ul>
							{/* {index === 0 ? (
								<ErrorBoundary>
									<AdSlotInterscroller sizes={[[1, 1]]} key={`interscroller-1`} />
								</ErrorBoundary>
							) : null} */}
						</Fragment>
				  ))
				: null}
		</div>
	);
};

import Head from 'next/head'
import React from 'react';

export class GoogleTagManager extends React.Component
{
	state = {
		scriptUrl: null
	}

	componentDidMount()
	{
		const layer = 'dataLayer'
		const id = this.props.gtmId

		window[layer] = window[layer] || []
		window[layer].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
		})

		const dl = layer != 'dataLayer' ? '&l=' + layer : '';

		this.setState({
			scriptUrl: 'https://www.googletagmanager.com/gtm.js?id=' + id + dl
		})
    }

	render()
	{
        return (
			this.state.scriptUrl != null ?
				<Head>
					<script src={this.state.scriptUrl} async key="script-google-tag-manager"></script>
				</Head>
				:
				null
        );
    }
}

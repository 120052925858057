import React, { FC } from "react";

type Props = {}

export const MediaWrapShowTitle: FC<Props> = ({ children }) =>
{
	return (
		<h3 className="b-media-wrap__show-title h4">{children}</h3>
	)
}

import { CookieConsent } from '../ui/common/CookieConsent';
import { useState, useEffect } from 'react';

type Props = {
	moreInfoUrl: string
}

export const CookieConsentBox = ({ moreInfoUrl }: Props) => {
	const [accepted, setAccepted] = useState(true);

	useEffect(() => {
		const cookieConsent = window.localStorage.getItem('cookieConsent');
		if(cookieConsent && cookieConsent === 'true') {
			setAccepted(true);
		}
		else {
			setAccepted(false);
		}
	}, []);

	/*return !accepted ? (
		<CookieConsent
			acceptText="Rozumím"
			onAccept={() => {
				setAccepted(true);
				window.localStorage.setItem('cookieConsent', 'true');
			}}
			moreInfoText="Další informace"
			moreInfoUrl={moreInfoUrl}
		>
			<h2>Cookies nám pomáhají k&nbsp;Vaší spokojenosti</h2>
			<p>
				Soubory cookies nám pomáhají řídit obsah stránky a&nbsp;reklamy tak, aby vyhovovaly Vašim představám.
				Nastavení můžete změnit v&nbsp;zápatí v&nbsp;odkazu Zásady ochrany osobních údajů.
			</p>
		</CookieConsent>
	) : null;*/
	return true;
};

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const ThumbsUpIcon: FC<Props> = ({ width = 18, height = 18 }) =>
{
	return (
		<IconWrapper id="thumbs-up" width={width} height={height} nativeWidth={18} nativeHeight={18}>
			<path d="M3.031 16V8h-1.01v8h1.01zm2.02-8.856V16h7.774c.98 0 1.622-.951 2.612-4.916.36-1.443.528-2.474.525-3.084H9.093V2h-.43c-.801 2.299-2.002 4.028-3.611 5.144zm0 10.856H0V6h3.085l.44-.26c1.55-.91 2.7-2.574 3.432-5.023L7.171 0h3.943v6l5.01.004L16.21 6c2.07-.36 2.166 1.65 1.188 5.564C16.204 16.35 15.088 18 12.825 18H5.052z"/>
		</IconWrapper>
	);
};

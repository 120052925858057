import React, { FC } from "react";

type Browser = {
	title: string,
	image: string,
	url: string,
}

type Props = {
	browsers?: Browser[],
}

export const BrowserSupport: FC<Props> = ({ browsers, children }) =>
{
	return (
		<div className="browser-support">
			<div className="browser-support__content">
				{children}
			</div>

			{browsers && (
				<ul className="browser-support__list">
					{browsers.map((browser) => (
						<li className="browser-support__item" key={browser.title}>
							<a
								href={browser.url}
								className="browser-support__link"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={browser.image}
									width="36"
									height="36"
									className="browser-support__img"
								/>
								<span className="browser-support__title">{browser.title}</span>
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}

import { FC, Component } from 'react';
import { hasBranding } from '../../../../utils/selectors';
import { StateConsumer } from '../../../ads/StateProvider';

export class PageHolder extends Component<Props> {
	render() {
		return (
			<StateConsumer>
				{state => <PageHolderWithState {...this.props} hasBranding={hasBranding(state)} />}
			</StateConsumer>
		);
	}
}

type Props = {
	bgImage?: string;
	hasBranding?: boolean;
	theme?: 'frekvence1' | 'danceradio' | 'bonton';
};

export const PageHolderWithState: FC<Props> = ({ hasBranding, theme, children }) => {
	return (
		<div className={`page-holder${hasBranding ? ' has-bg' : ''}`} data-theme={theme}>
			<div className="page-holder__bg"></div>
			<div className="page-holder__wrap">{children}</div>
		</div>
	);
};

import React, { FC } from "react";
import classNames from 'classnames';

type Props = {
	title?: string,
	variant?: 'links',
}

export const SocialShare: FC<Props> = ({ title, variant, children }) =>
{
	const classes = classNames(
		'm-socials',
		variant ? `m-socials--${variant}` : '',
 	)

	return (
		<div className={classes}>
			{title && (
				<p className="m-socials__title">{title}</p>
			)}
			{
				React.Children.count(children) > 0 &&
					<ul className="m-socials__list">
						{
							React.Children.map(children, child => (
								<li className="m-socials__item">
									{child}
								</li>
							))
						}
					</ul>
			}
		</div>
	)
}

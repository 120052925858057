import React, { FC } from 'react';

type Props = {
	onHover?: () => void;
	onBlur?: () => void;
}

export const SubmenuChannels: FC<Props> = ({ onHover, onBlur, children }) => {
	return (
		<div className="b-submenu-channels" onMouseEnter={onHover} onMouseLeave={onBlur}>
			<div className="b-submenu-channels__wrap">
				{children}
			</div>
		</div>
	)
}

import { FC } from 'react';
import dynamic from 'next/dynamic';

const loader = () => import('react-dfp').then((module) => module.DFPSlotsProvider);
const DFPSlotsProvider = dynamic(loader);

type Props = {
	dfpNetworkId?: string;
};

export const CustomDFPSProvider: FC<Props> = ({ dfpNetworkId, children }) => {
	return dfpNetworkId ? (
		<DFPSlotsProvider dfpNetworkId={dfpNetworkId} collapseEmptyDivs>
			{children}
		</DFPSlotsProvider>
	) : (
		<>{children}</>
	);
};

import fetch from 'isomorphic-fetch';
import * as React from 'react';
import { Component } from 'react';
// @ts-ignore
import { Link } from '../../../../evropa2/src/server/routes';
import { HistoryItem, Metadata, Channel, PlayerState } from '../../types/common';
import { DEFAULT_CHANNEL, PLAYLIST_ITEMS_COUNT } from '../../constants';
import entities from "entities";
import { parseXml } from '../../utils/api/parseXml';
import { TritonHistory } from '../../types/rss';

// let laPlayerInitialized: boolean = false;

export type TritonPlayerProps = {
	urlCode: string,
	radioCode?: string,
	metadata?: Metadata | null,
	onHistoryLoaded?: (history: HistoryItem[]) => void,
	updateMetadata?: (metadata: Metadata) => void,
	onChannelsLoaded?: (channels: Channel[]) => void,
	onStateChange?: (state: PlayerState) => void,
	proposedState: PlayerState,
	progress?: number,
	volume: number,
	onVolumeChange?: (volume: number) => void,
	onVolumeMinusClick?: () => void,
	onVolumePlusClick?: () => void,
	autoplay: boolean,
	trackingParameters?: {[v: string]: any},
	station: string,
}

type StreamStatusEvent = {
	data: {
		code: 'LIVE_STOP' | 'LIVE_PAUSE' | 'LIVE_PLAYING',
	}
}

type TrackCuePointEvent = {
	data: {
		cuePoint: {
			artistName: string,
			cueTitle: string,
		}
	}
}

export class TritonPlayer extends Component<TritonPlayerProps>
{
	// adDivRef = React.createRef<HTMLDivElement>()

	player: any

	render()
	{
		return (
			<div id="td_container" style={{ height: 0 }}></div>
		);
	}

	async componentDidMount()
	{
		this.initPlayerSDK();
	}

	componentWillUnmount()
	{
		if(this.player) {
			this.player.removeEventListener('track-cue-point', this.onTrackCuePoint);
			this.player.removeEventListener('speech-cue-point', this.onSpeechCuePoint);
			this.player.removeEventListener('custom-cue-point', this.onCustomCuePoint);
			// this.player.removeEventListener('stream-start', this.streamStarted);
			// this.player.removeEventListener('stream-stop', this.streamStopped);
			this.player.removeEventListener('list-loaded', this.onListLoaded);
			this.player.removeEventListener('stream-status', this.onStreamStatus);
			this.player.stop();
		}
		this.player = null;
	}

	componentDidUpdate(prevProps: TritonPlayerProps)
	{
		if(prevProps.urlCode !== this.props.urlCode) {
			if(this.props.urlCode === DEFAULT_CHANNEL) {
				this.player && this.play();
			}
			else {
				this.player && this.player.stop();
			}
		}

		if(prevProps.volume !== this.props.volume || (prevProps.urlCode !== this.props.urlCode && this.props.urlCode === DEFAULT_CHANNEL)) {
			this.player && this.player.setVolume(this.props.volume / 100);
		}

		if(prevProps.proposedState !== this.props.proposedState) {
			switch (this.props.proposedState) {
				case PlayerState.Playing:
					this.player && this.play();
					break;
				case PlayerState.Stopped:
					this.player && this.player.stop();
					break;
			}
		}

		if(prevProps.station !== this.props.station) {
			this.player && this.player.stop();
			this.player && this.play();
		}

		// if(prevProps.trackingParameters !== this.props.trackingParameters) {
		// 	if(this.props.proposedState === PlayerState.Playing && this.player) {
		// 		this.player.stop();
		// 		this.play();
		// 	}
		// }
	}

	initPlayerSDK()
	{
		//Player SDK is ready to be used, this is where you can instantiate a new TDSdk instance.
		//Player configuration: list of modules
		if(window.tritonPlayer) {
			this.player = window.tritonPlayer;
			this.onPlayerReady();
		}
		else {
			const tdPlayerConfig = {
				coreModules: [{
					id: 'MediaPlayer',
					playerId: 'td_container',
					idSync: {
						station: this.props.station
					}
				}],
				playerReady: this.onPlayerReady,
				configurationError: this.onConfigurationError,
				moduleError: this.onModuleError,
				adBlockerDetected: this.onAdBlockerDetected,
			};

			//Player instance
			this.player = new window.TDSdk(tdPlayerConfig);
			window.tritonPlayer = this.player;
		}
	}

	play()
	{
		this.player && this.player.setVolume(this.props.volume / 100);
		this.player.play({
			station: this.props.station,
			//trackingParameters: { ttag: 's:56822' }
			...(
				Object.keys(JSON.parse(localStorage['_pdfps'] || '[]')).length > 0 && {
					trackingParameters: { ttag: JSON.parse(localStorage['_pdfps'] || '[]').splice(0, 150).join(',')}
				}
			)
		});
	}

	/* Callback function called to notify that the SDK is ready to be used */
	onPlayerReady = async () =>
	{
		//Listen for 'track-cue-point' event
		this.player.addEventListener('track-cue-point', this.onTrackCuePoint);
		this.player.addEventListener('speech-cue-point', this.onSpeechCuePoint);
		this.player.addEventListener('custom-cue-point', this.onCustomCuePoint);
		// this.player.addEventListener('stream-start', this.streamStarted);
		// this.player.addEventListener('stream-stop', this.streamStopped);
		this.player.addEventListener('list-loaded', this.onListLoaded);
		this.player.addEventListener('stream-status', this.onStreamStatus);


		// @ts-ignore
		const { default: canAutoplay } = await import('can-autoplay');
		const { result } = await canAutoplay.audio();

		if((this.props.autoplay === false || result === false) && this.props.onStateChange) {
			this.props.onStateChange(PlayerState.Stopped);
		}
		else {
			this.play();
		}
	}

	onStreamStatus = (e: StreamStatusEvent) =>
	{
		switch (e.data.code) {
			case 'LIVE_STOP':
			case 'LIVE_PAUSE':
				this.props.onStateChange && this.props.onStateChange(PlayerState.Stopped);
				break;
			case 'LIVE_PLAYING':
				this.props.onStateChange && this.props.onStateChange(PlayerState.Playing);
				break;
			default:
				this.props.onStateChange && this.props.onStateChange(PlayerState.Waiting);
				break;
		}
	}

	/* Callback function called to notify that the player configuration has an error. */
	onConfigurationError = () =>
	{
		//Error code : object.data.errors[0].code
		//Error message : object.data.errors[0].message
	}

	/* Callback function called to notify that a module has not been loaded properly */
	onModuleError = (_object: any) =>
	{
		//Error code : object.data.errors[0].code
		//Error message : object.data.errors[0].message
	}

	/* Callback function called to notify that a new Track CuePoint comes in. */
	onTrackCuePoint = (e: TrackCuePointEvent) =>
	{
		if(this.props.updateMetadata) {
			const cueData = e.data.cuePoint;

			const metadata = {
				album: null,
				moderatorName: null,
				showName: null,
				moderatorMotiveURL: null,
				cover: null,
				showStart: null,
				showEnd: null,
				artist: cueData.artistName,
				title: cueData.cueTitle,
				name: null,
			};
			this.props.updateMetadata(metadata);
		}
		if(this.props.onHistoryLoaded) {
			this.loadHistory();
		}
	}

	onSpeechCuePoint = () =>
	{
	}

	onCustomCuePoint = () =>
	{
	}

	/* Callback function called to notify that an Ad-Blocker was detected */
	onAdBlockerDetected = () =>
	{
	}

	onListLoaded = () =>
	{
	}

	async loadHistory()
	{
		const url = `http://np.tritondigital.com/public/nowplaying?mountName=EVROPA2AAC&numberToFetch=${PLAYLIST_ITEMS_COUNT}`
		try {
			const response = await fetch(url);
			const xml = await response.text();
			const json = await parseXml<TritonHistory>(xml);

			type HistoryItem = {
				songTitle: string | null,
				songArtist: string | null,
				songStart: string | null,
				songDuration: string | null,
			}

			const history = json['nowplaying-info-list']["nowplaying-info"].map(item => {

				const historyItem: HistoryItem = item.property.reduce((res: HistoryItem, curr) => {
					switch (curr.$.name) {
						case 'cue_title':
							res.songTitle = curr._;
							break;
						case 'track_artist_name':
							res.songArtist = curr._;
							break;
						case 'cue_time_start':
							// res.songStart = new Date().toISOString();
							res.songStart = new Date(Math.floor(parseFloat(curr._))).toISOString()//.replace('T', ' ').replace(/\.\d+Z$/, '');
							break;
						case 'cue_time_duration':
							res.songDuration = curr._;
							break;
					}
					return res;
				}, {
					songTitle: null,
					songArtist: null,
					songStart: null,
					songDuration: null,
				})

				return {
					cover: null,
					favorite: null,
					mediaId: null,
					movieUrl: null,
					songAlbum: null,
					songArtist: null,
					songDuration: null,
					songStart: null,
					songTitle: null,
					yrArtistNormalizedName: null,
					yrSongId: null,
					...historyItem,
				}
			})

			if(this.props.onHistoryLoaded) {
				this.props.onHistoryLoaded(history);
			}
		}
		catch (e) {
			console.error(e);
			return null;
		}
	}

	// onInitDone = () =>
	// {
	// 	laPlayerInitialized = true;
	// }

	// onChannelsLoaded = (channels: Channel[]) =>
	// {
	// 	if(this.props.onChannelsLoaded)
	// 	{
	// 		this.props.onChannelsLoaded(channels);
	// 	}
	// }

	// onHistoryLoaded = (history: HistoryItem[]) =>
	// {
	// 	const historySanitized = history.map(item => ({
	// 		...item,
	// 		songTitle: entities.decodeHTML(item.songTitle),
	// 		songArtist: entities.decodeHTML(item.songArtist),
	// 	}));

	// 	if(this.props.onChannelsLoaded)
	// 	{
	// 		this.props.onHistoryLoaded(historySanitized)
	// 	}
	// }

	updateMetadata = (metadata: Metadata) =>
	{
		const { artist, title, album, moderatorName, showName, moderatorMotiveURL, cover, showStart, showEnd, name } = metadata;
		const metadataSanitized = {
			album, moderatorName, showName, moderatorMotiveURL, cover, showStart, showEnd,
			artist: entities.decodeHTML(artist || ''),
			title: entities.decodeHTML(title || ''),
			name: entities.decodeHTML(name || ''),
		};
		this.props.updateMetadata && this.props.updateMetadata(metadataSanitized);
	}

	streamStarted = (_e: any) =>
	{
		this.props.onStateChange && this.props.onStateChange(PlayerState.Playing);
	}

	streamStopped = (_e: any) =>
	{
		this.props.onStateChange && this.props.onStateChange(PlayerState.Stopped);
	}
}




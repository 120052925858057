import React, { FC } from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { FacebookIcon } from '../icons/FacebookIcon'
import { TwitterIcon } from '../icons/TwitterIcon'

type Props = {
	variant: 'facebook' | 'twitter'
	url: string,
	srHeading?: string,
}

export const SocialShareBtn: FC<Props> = ({ variant, url, srHeading }) => {
	const heading = srHeading != null ? <span className="u-vhide">{srHeading}</span> : ''

	switch (variant) {
		case 'facebook':
			return (
				<FacebookShareButton url={url}>
					<FacebookIcon />
					{heading}
				</FacebookShareButton>
			)
		case 'twitter':
			return (
				<TwitterShareButton url={url}>
					<TwitterIcon />
					{heading}
				</TwitterShareButton>
			)
		default:
			return null;
	}
}

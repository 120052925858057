import { RssItem } from '../../../types/rss';
import { FC, SyntheticEvent, ReactNode } from 'react';
import { MediaWrapImgPlay } from '../mediaWrap/MediaWrapImgPlay';

type Props = {
	item: RssItem;
	href?: string;
	onClick?: (e: SyntheticEvent) => void;
	tag?: ReactNode;
};

export const ShowItem: FC<Props> = ({ item, href, onClick, tag }) => {
	return (
		<a href={href} className="b-media-item b-media-item--show" onClick={onClick}>
			{item['media:thumbnail'] != null && item['media:thumbnail'].$ != null && (
				<MediaWrapImgPlay thumbnail={item['media:thumbnail'].$.url} />
			)}

			<div className="b-media-item__content">
				<p className="b-media-item__title">{item.title}</p>
				{tag && <p className="b-media-item__tag">{tag}</p>}
			</div>
		</a>
	);
};

// @ts-ignore-no-unused-parameters
const defaultRenderItemLink = (item, children) => children;

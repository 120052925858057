import { TabsNav } from '../ui/tabsNav/TabsNav';
import { TabsNavItem } from '../ui/tabsNav/TabsNavItem';
import { createChannelParam } from '../../utils/createChannelParam';
import { createRouteParam } from '../../utils/createRouteParam';
import Link from 'next/link';
import { filterNullProps } from '../../utils/filterNullProps';

type Props = {
	urlCode: string;
	play?: string;
	archive?: string;
	scrollToMain?: () => void;
};

export const HeaderTabs = ({ urlCode, play, archive, scrollToMain }: Props) => {
	return (
		<TabsNav isSmall>
			<Link
				href={{
					pathname: '/',
					query: filterNullProps({
						...createChannelParam(urlCode),
						...createRouteParam('play', play),
					})
				}}
				shallow
				passHref
				scroll={false}
			>
				<TabsNavItem isActive={archive == null} onClick={scrollToMain}>
					Playlist
				</TabsNavItem>
			</Link>
			<Link
				href={{
					pathname: '/',
					query: filterNullProps({
						archive: 'zaznamy',
						...createChannelParam(urlCode),
						...createRouteParam('play', play),
					})
				}}
				shallow
				passHref
				scroll={false}
			>
				<TabsNavItem isActive={archive === 'zaznamy'} onClick={scrollToMain}>
					Záznamy
				</TabsNavItem>
			</Link>
			<Link
				href={{
					pathname: '/',
					query: filterNullProps({
						archive: 'porady',
						...createChannelParam(urlCode),
						...createRouteParam('play', play),
					})
				}}
				shallow
				passHref
				scroll={false}
			>
				<TabsNavItem isActive={archive === 'porady'} onClick={scrollToMain}>
					Pořady
				</TabsNavItem>
			</Link>
		</TabsNav>
	);
};

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const TwitterIcon: FC<Props> = ({ width = 28, height = 28 }) =>
{
	return (
		<IconWrapper id="twitter" width={width} height={height} nativeWidth={28} nativeHeight={28}>
			<path fill="#00ABEF" d="M20.111 9.206A3.49 3.49 0 0 0 21.55 7.26c-.632.41-1.338.7-2.08.856A3.165 3.165 0 0 0 17.07 7c-1.904.099-3.368 1.678-3.275 3.534.001.271.028.542.08.809-2.708-.184-5.188-1.541-6.76-3.698A3.706 3.706 0 0 0 6.67 9.43a3.611 3.611 0 0 0 1.462 2.942 3.127 3.127 0 0 1-1.482-.45v.044c-.059 1.611 1.03 3.049 2.628 3.468a3.24 3.24 0 0 1-.862.12 3.836 3.836 0 0 1-.62-.055c.361 1.383 1.603 2.375 3.065 2.45a6.27 6.27 0 0 1-4.07 1.51A6.257 6.257 0 0 1 6 19.415 8.783 8.783 0 0 0 11.033 21c6.029 0 9.328-5.381 9.328-10.051 0-.156 0-.307-.01-.46A7.363 7.363 0 0 0 22 8.66a6.222 6.222 0 0 1-1.889.545z"/>
		</IconWrapper>
	);
};

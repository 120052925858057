import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const PlayTriangleIcon: FC<Props> = ({ width = 5, height = 6 }) =>
{
	return (
		<IconWrapper id="play-triangle" width={width} height={height} nativeWidth={5} nativeHeight={6}>
			<path d="M0 0l5 3-5 2.999V0zm1 1.766v2.468L3.056 3 1 1.766z"/>
		</IconWrapper>
	);
};

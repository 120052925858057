import * as React from 'react';

type Props = {
	src: string,
	async?: boolean,
	onLoad?: Function,
	onError?: Function,
};

const loadedScripts: {[key: string]: boolean} = {};

export class Script extends React.Component<Props>
{
	script?: HTMLScriptElement

	render()
	{
		return null;
	}

	shouldComponentUpdate()
	{
		return false;
	}

	componentDidMount()
	{
		const { src, async = false } = this.props;
		if(loadedScripts[this.props.src]) {
			this.onLoad();
		}
		else {
			this.script = window.document.createElement('script');

			this.script.addEventListener('load', this.onLoad, false);
			this.script.addEventListener('error', this.onLoad, false);

			this.script.async = async;
			this.script.src = src;

			window.document.body.appendChild(this.script);
		}
	}

	componentWillUnmount()
	{
		if(this.script) {
			this.script.removeEventListener('load', this.onLoad, false);
			this.script.removeEventListener('error', this.onLoad, false);

			window.document.body.removeChild(this.script);
		}
	}

	onLoad = () =>
	{
		loadedScripts[this.props.src] = true;
		this.props.onLoad && this.props.onLoad();
	}

	onError = () =>
	{
		this.props.onError && this.props.onError();
	}
}

import React, { FC } from 'react';

type Props = {};

export const MediaWrapDayGroup: FC<Props> = ({ children }) => {
	return (
		<div className="c-media-list c-media-list--records">
			{React.Children.count(children) > 0 && (
				<ul className="c-media-list__list">
					{React.Children.map(children, child => (
						<li className="c-media-list__item">{child}</li>
					))}
				</ul>
			)}
		</div>
	);
};

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const AngleDownIcon: FC<Props> = ({ width = 18, height = 18 }) =>
{
	return (
		<IconWrapper id="angle-d" width={width} height={height} nativeWidth={18} nativeHeight={18}>
			<path d="M9 9.8L13.5 5 15 6.6 9 13 3 6.6 4.5 5z"/>
		</IconWrapper>
	);
};

import { FC } from "react";

type Props = {}

export const Footer: FC<Props> = ({ children }) =>
{
	return (
		<footer role="contentinfo" className="footer">
			{children}
		</footer>
	)
}

import { FC, SyntheticEvent, forwardRef, RefObject } from 'react';
import { RssItem } from '../../../types/rss';
import { formatTime } from '../../../utils/format';
import { MediaWrapImgPlay } from '../mediaWrap/MediaWrapImgPlay';

type Props = {
	item: RssItem;
	showName?: string;
	href?: string;
	onClick?: (e: SyntheticEvent) => void;
	ref?: RefObject<HTMLAnchorElement>;
};

export const RecordItem: FC<Props> = forwardRef(({ item, showName, href, onClick }, ref) => {
	return (
		<a href={href} className="b-media-item b-media-item--record" onClick={onClick} ref={ref}>
			<div className="b-media-item__content">
				<p className="b-media-item__title">{item.title}</p>
				<p className="b-media-item__meta">
					{!!item['itunes:duration'] && formatTime(item['itunes:duration'])}
					{showName != null ? ` · ${showName}` : ''}
				</p>
			</div>

			{item['media:thumbnail'] != null && item['media:thumbnail'].$ != null && (
				<MediaWrapImgPlay thumbnail={item['media:thumbnail'].$.url} />
			)}
		</a>
	);
});

// @ts-ignore-no-unused-parameters
const defaultRenderItemLink = (item, children) => children;

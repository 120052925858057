import { FC } from "react";
import classNames from 'classnames'

type Props = {
	isOpened?: boolean,
}

export const Sidebar: FC<Props> = ({ isOpened, children }) =>
{
	const classes = classNames(
		'sidebar',
		isOpened ? 'is-opened' : '',
	)

	return (
		<div className={classes}>
			<div className="sidebar__wrap">
				{children}
			</div>
		</div>
	)
}


type Param =  string | null | undefined;
type Params =  { [param: string]: Param };

export const makeRouterParams = (params: Params, defaultParams: Params = {}) =>
{
	const newParams = Object
		.keys(params)
		.filter(filterByNonNull(params, defaultParams))
		.reduce(reduceToParams(params), {})
	if(Object.keys(newParams).length === 0) return undefined;
	return newParams;
}

const filterByNonNull = (params: Params, defaultParams: Params) => (key: string) => params[key] != null && params[key] != '' && params[key] !== defaultParams[key];

const reduceToParams = (params: Params) => (acc: Params, key: string): Params => {
	acc[key] = params[key];
	return acc;
}
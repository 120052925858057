import Head from "next/head";
import { FC } from "react";

export const Netmonitor: FC = () =>
{
	return (
		<Head>
			<script
				key="script-netmonitor"
				src="https://scz.hit.gemius.pl/gstream.js"
			/>
		</Head>
	)
}
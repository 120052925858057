import { FC, ReactNode } from "react";

type Props = {
	heading?: ReactNode,
	player: ReactNode,
	sideContent?: ReactNode,
}

export const RecordPlayer: FC<Props> = ({ heading, player, sideContent }) => {
	return (
		<div className="b-record-player">
			{
				heading != null &&
				<p className="b-record-player__heading">{heading}</p>
			}

			<div className="b-record-player__wrap">
				<div className="b-record-player__holder">
					{player}
				</div>

				{
					sideContent != null &&
					<div className="b-record-player__side">
						{sideContent}
					</div>
				}
			</div>
		</div>
	)
}

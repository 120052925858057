import Head from 'next/head';
import { FC } from 'react';

type Props = {
	gemiusPlayerName: string;
	gemiusPlayerId: string;
};

export const GemiusPlayer: FC<Props> = ({ gemiusPlayerName, gemiusPlayerId }) => {
	return (
		<Head>
			<script
				key="script-gemius-player"
				dangerouslySetInnerHTML={{
					__html: `
					//netmonitor stream measuring
					<!--//--><![CDATA[//><!--
					function gemius_player_pending(obj,fun) {obj[fun] = obj[fun] || function() {var x = window['gemius_player_data'] = window['gemius_player_data'] || [];
					x[x.length]=[this,fun,arguments];};};
					gemius_player_pending(window,"GemiusPlayer");
					gemius_player_pending(GemiusPlayer.prototype,"newProgram");
					gemius_player_pending(GemiusPlayer.prototype,"newAd");
					gemius_player_pending(GemiusPlayer.prototype,"adEvent");
					gemius_player_pending(GemiusPlayer.prototype,"programEvent");
					(function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');
					gt.setAttribute('defer','defer'); gt.src=l+'://scz.hit.gemius.pl/gplayer.js';
					s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');
					//--><!]]>

					var player = new GemiusPlayer('${gemiusPlayerName}','${gemiusPlayerId}');


					player.newProgram(01,
					{ 'programType':'Audio',
					'programDuration':-1,
					'programName':'On air E2'
					});
				`,
				}}
			/>
		</Head>
	);
};

import { FC } from "react";
import { times } from 'ramda';
import classNames from 'classnames';

type Props = {
	small?: boolean,
}

export const Equalizer: FC<Props> = ({ small }) =>
{
	const BARS_COUNT = small ? 20 : 32;
	const bars = times((i) => <span className="equalizer__bar" key={i} />, BARS_COUNT)

	const classes = classNames(
		'equalizer',
		small ? 'equalizer--sm' : '',
	)

	return (
		<span className={classes}>
			{bars}
		</span>
	)
}

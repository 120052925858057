import fetch from 'isomorphic-fetch';
import { RssItem, RssRecordWrapper } from '../../types/rss';
import { parseXml } from './parseXml';

export const fetchRecord = async (url: string, play: string, param: string = 'id', fetchOptions?: RequestInit): Promise<RssItem | null> => {
	try {
		const response = await fetch(`${url}?${param}=${play}`, fetchOptions || {});
		const xml = await response.text();
		const json = await parseXml<RssRecordWrapper>(xml);
		if(json != null && json.rss != null && json.rss.channel != null && json.rss.channel.item != null)
		{
			return json.rss.channel.item;
		}
		else return null;
	}
	catch (e) {
		console.error(e);
		return null;
	}
};


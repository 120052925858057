import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const PlusIcon: FC<Props> = ({ width = 18, height = 18 }) =>
{
	return (
		<IconWrapper id="plus" width={width} height={height} nativeWidth={18} nativeHeight={18}>
			<path d="M10 8h3a1 1 0 0 1 0 2h-3v3a1 1 0 0 1-2 0v-3H5a1 1 0 1 1 0-2h3V5a1 1 0 1 1 2 0v3z"/>
		</IconWrapper>
	);
};

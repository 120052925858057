import { FC } from "react";

type Props = {
	href: string,
} | React.AnchorHTMLAttributes<HTMLAnchorElement>

export const FooterItem: FC<Props> = ({ href, children, ...restProps }) =>
{
	return (
		<a href={href} className="footer__item" {...restProps}>{children}</a>
	)
}

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const CloseIcon: FC<Props> = ({ width = 18, height = 18 }) =>
{
	return (
		<IconWrapper id="close" width={width} height={height} nativeWidth={18} nativeHeight={18}>
			<path d="M10.429 9L14 12.571 12.571 14 9 10.429 5.429 14 4 12.571 7.571 9 4 5.429 5.429 4 9 7.571 12.571 4 14 5.429z"/>
		</IconWrapper>
	);
};

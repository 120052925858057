import React, { FC } from "react";

type Props = {}

export const MediaWrapBtn: FC<Props> = ({ children }) =>
{
	return (
		<p className="b-media-wrap__btn">{children}</p>
	)
}

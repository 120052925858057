import { Following } from '../ui/player/Following';
import { Metadata } from '../../types/common';
import { LiveRds } from './LiveRdsTypes';
import { useState, useEffect } from 'react';

type Props = {
	metadata?: Metadata | null;
	url: string;
	programUrl: string;
};

export const NextProgramFromRds = ({ url, metadata, programUrl }: Props) => {
	const [liveRds, setLiveRds] = useState<LiveRds | null>(null);

	useEffect(() => {
		fetchLiveRds(url, setLiveRds);
	}, [metadata?.showStart]);

	return liveRds != null ? (
		<Following
			heading="Následuje"
			showName={liveRds.future_1.showName}
			showTime={`${liveRds.future_1.showStart}–${liveRds.future_1.showEnd}`}
			moderatorName={liveRds.future_1.moderators?.map(moderator => moderator.name).join(', ')}
			programUrl={programUrl}
		/>
	) : null;
};

const fetchLiveRds = async (url: string, callback: (liveRds: LiveRds) => void) => {
	try {
		const response = await fetch(url);
		const json = await response.json();
		callback(json);
	} catch (e) {}
};

import { BrowserSupport } from '../ui/common/BrowserSupport';
import { useEffect, useState } from 'react';
import browserChromeImage from '../../../../evropa2/public/static/images/browser_chrome@2x.png';
import browserFirefoxImage from '../../../../evropa2/public/static/images/browser_firefox@2x.png';
import browserEdgeImage from '../../../../evropa2/public/static/images/browser_edge@2x.png';

export const LegacyBrowserBox = () => {
	const [showBox, setShowBox] = useState(false);
	useEffect(() => {
		setShowBox(isMsie());
	}, []);
	return showBox ? (
		<BrowserSupport
			browsers={[
				{
					title: 'Google Chrome',
					image: browserChromeImage,
					url: 'https://www.google.com/chrome/',
				},
				{
					title: 'Mozilla Firefox',
					image: browserFirefoxImage,
					url: 'https://www.firefox.cz/',
				},
				{
					title: 'Microsoft Edge',
					image: browserEdgeImage,
					url: 'https://www.microsoft.com/cs-cz/windows/microsoft-edge/',
				},
			]}
		>
			<p>
				<strong>Tvůj prohlížeč není plně podporován.</strong>
			</p>
			<p>Zkus některý z těchto modernějších.</p>
		</BrowserSupport>
	) : null;
};

const isMsie = () => {
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf('MSIE ');

	return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
};

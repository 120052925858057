import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const PlayIcon: FC<Props> = ({ width = 30, height = 34 }) =>
{
	return (
		<IconWrapper id="play" width={width} height={height} nativeWidth={30} nativeHeight={34}>
			<path d="M0 34V0l30 17L0 34zM2.069 3.524v26.952L25.85 17 2.07 3.524z"/>
		</IconWrapper>
	);
};


export const truncate = (text: string, maxLen: number) => {
	if (text.length > maxLen + 3) {
		let toIndex = text.lastIndexOf(' ', maxLen);
		if (toIndex === -1) {
			toIndex = maxLen;
		}
		return `${text.slice(0, toIndex)}...`;
	}

	return text;
}

import React, { FC } from 'react';
import { RecordsList } from '../laplayer/RecordsList';
import { MediaWrap } from '../ui/mediaWrap/MediaWrap';
import { MediaWrapBtn } from '../ui/mediaWrap/MediaWrapBtn';
import { ShowItem } from '../ui/shows/ShowItem';
import { ShowsList } from '../ui/shows/ShowsList';
import { TabsNav } from '../ui/tabsNav/TabsNav';
import { TabsNavItem } from '../ui/tabsNav/TabsNavItem';
import { AnchorButton } from '../ui/controls/AnchorButton';
import { Rss } from '../../types/rss';
import { MediaWrapHeading } from '../ui/mediaWrap/MediaWrapHeading';
import { MediaWrapShowHeading } from '../ui/mediaWrap/MediaWrapShowHeading';
import { MediaWrapShowTitle } from '../ui/mediaWrap/MediaWrapShowTitle';
import { MediaWrapBacklink } from '../ui/mediaWrap/MediaWrapBacklink';
import { createChannelParam } from '../../utils/createChannelParam';
import { createRouteParam } from '../../utils/createRouteParam';
// import { Tag } from '../ui/common/Tag';
import { ShowOnTabletUp } from '../ui/layout/ShowOnTabletUp';
import Link from 'next/link';
import { filterNullProps } from '../../utils/filterNullProps';

type Props = {
	urlCode: string,
	archive?: string,
	feedRecords: Rss | null,
	feedShows: Rss | null,
	showRecords: Rss | null,
	show?: string,
	showName?: string | null,
	play?: string | null,
	archiveUrl: string,
	showsLastItemIds?: { [key: string]: string },
}

export const Archive: FC<Props> = React.memo(({ urlCode, archive, feedRecords, show, showRecords, feedShows, showName, play, archiveUrl/* , showsLastItemIds */ }) => {
	return (
		<MediaWrap>
			<ShowOnTabletUp>
				<TabsNav isActiveUnderline>
					<Link
						href={{
							pathname: '/',
							query: filterNullProps({
								...createChannelParam(urlCode),
								...createRouteParam('play', play),
							})
						}}
						shallow
						passHref
						scroll={false}
					>
						<TabsNavItem isActive={archive !== 'porady'} titleSize>Záznamy</TabsNavItem>
					</Link>
					<Link
						href={{
							pathname: '/',
							query: filterNullProps({
								archive: 'porady',
								...createChannelParam(urlCode),
								...createRouteParam('play', play),
							})
						}}
						shallow
						passHref
						scroll={false}
					>
						<TabsNavItem isActive={archive === 'porady'} titleSize>Pořady</TabsNavItem>
					</Link>
				</TabsNav>
			</ShowOnTabletUp>
			{
				archive !== 'porady' && feedRecords != null &&
					<>
						<MediaWrapHeading isHidden>Záznamy</MediaWrapHeading>
						<RecordsList
							rss={feedRecords}
							archive={archive}
							channel={urlCode}
							feedShows={feedShows}
						/>
						<MediaWrapBtn>
							<AnchorButton
								href={archiveUrl}
								target="_blank"
								rel="noopener"
							>
								Všechny záznamy
							</AnchorButton>
						</MediaWrapBtn>
					</>
			}
			{
				archive === 'porady' && (
					show == null ?
						feedShows != null &&
							<>
								<MediaWrapHeading isHidden>Pořady</MediaWrapHeading>
								<ShowsList>
									{
										feedShows.channel.item.map((item, i) =>
											<Link
												href={{
													pathname: '/',
													query: filterNullProps({
														archive: 'porady',
														show: item.show,
														...createChannelParam(urlCode),
														...createRouteParam('play', play),
													})
												}}
												passHref
												scroll={false}
												key={i}
											>
												<ShowItem
													item={item}
													tag={
														null
														// TODO
														// showsLastItemIds == null || (showsLastItemIds[item.show] != null && showsLastItemIds[item.show] === item.lastItemId) ?
														// 	null
														// 	:
														// 	<Tag>Nové díly</Tag>
													}
												/>
											</Link>
										)
									}
								</ShowsList>
							</>
						:
						showRecords != null &&
							<>
								<MediaWrapShowHeading>
									<MediaWrapShowTitle>{showName}</MediaWrapShowTitle>
									<Link
										href={{
											pathname: '/',
											query: filterNullProps({
												archive: 'porady',
												...createChannelParam(urlCode),
												...createRouteParam('play', play),
											})
										}}
										shallow
										scroll={false}
									>
										<MediaWrapBacklink>&lt; Zpět</MediaWrapBacklink>
									</Link>
								</MediaWrapShowHeading>
								<RecordsList
									rss={showRecords}
									archive={archive}
									show={show}
									channel={urlCode}
									feedShows={feedShows}
								/>
								<MediaWrapBtn>
									<AnchorButton
										href={`${archiveUrl}${show}`}
										target="_blank"
										rel="noopener"
									>
										Všechny díly
									</AnchorButton>
								</MediaWrapBtn>
							</>
				)
			}
		</MediaWrap>
	)
});

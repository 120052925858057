import xml2js from 'xml2js';

export async function parseXml<T>(xml: string): Promise<T> {
	return new Promise((resolve, reject) => {
		const parser = new xml2js.Parser({
			explicitArray: false
		});
		parser.parseString(xml, (err: Error | undefined, result: T) => {
			if (err)
				reject(err);
			else
				resolve(result);
		});
	});
};

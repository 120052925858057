import React, { FC, SyntheticEvent } from "react";

type Props = {
	href?: string,
	onClick?: (e: SyntheticEvent) => void,
}

export const MediaWrapBacklink: FC<Props> = ({ href, onClick, children }) =>
{
	return (
		<a href={href} className="b-media-wrap__back" onClick={onClick}>{children}</a>
	)
}

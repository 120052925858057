import React, { FC } from "react";

type Props = {}

export const MediaWrapShowHeading: FC<Props> = ({ children }) =>
{
	return (
		<div className="b-media-wrap__show-heading">
			{children}
		</div>
	)
}

export const formatTime = (timeString: string) => {
	const parts = ['00', '00', '00', ...timeString.split(':')].reverse().map(part => parseInt(part, 10));
	const [seconds, minutes, hours] = parts;

	if(hours > 0) {
		return [String(hours), zeroPad2(minutes)].join(':');
	}
	else {
		return [String(minutes), zeroPad2(seconds)].join(':');
	}
};

const zeroPad2 = (x: number) => (x < 10 ? `0${x}` : `${x}`);

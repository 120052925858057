import { parseApiTime } from './program/parseApiTime';
import { timePropsToDate } from './dateUtils';

export const computeShowProgress = (today: Date, showStart: string, showEnd: string) =>
{
	const showStartTimeProps = parseApiTime(showStart);
	const showEndTimeProps = parseApiTime(showEnd);

	if(showStartTimeProps != null && showEndTimeProps != null) {
		const showStartDate = timePropsToDate(today, showStartTimeProps);
		const showEndDate = timePropsToDate(today, showEndTimeProps);
		return (today.getTime() - showStartDate.getTime()) / (showEndDate.getTime() - showStartDate.getTime()) * 100;
	}
	return 0
}
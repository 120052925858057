import { FC } from "react";
import Slider from 'rc-slider';
import { MinusIcon } from '../icons/MinusIcon'
import { PlusIcon } from '../icons/PlusIcon'
// @ts-ignore
import Repeatable from "react-repeatable"

type Props = {
	value: number,
	onChange?: (value: number) => void,
	onMinusClick?: () => void,
	onPlusClick?: () => void,
}

export const VolumeSlider: FC<Props> = ({ value, onChange, onMinusClick, onPlusClick }) =>
{
	return (
		<div className="volume-slider">
			<Repeatable
				repeatDelay={150}
				repeatInterval={50}
				onPress={onMinusClick}
				onHold={onMinusClick}
			>
				<a className="volume-slider__btn volume-slider__btn--minus">
					<MinusIcon />
				</a>
			</Repeatable>
			<div className="volume-slider__holder">
				<Slider min={0} max={100} step={1} value={value} onChange={onChange} />
			</div>
			<Repeatable
				repeatDelay={150}
				repeatInterval={50}
				onPress={onPlusClick}
				onHold={onPlusClick}
			>
				<a className="volume-slider__btn volume-slider__btn--plus">
					<PlusIcon />
				</a>
			</Repeatable>
		</div>
	)
}

import * as React from 'react';
import { useScript } from '../../hooks/useScript';
import { TritonPlayer, TritonPlayerProps } from '../laplayer/TritonPlayer';

export const TritonAsync: React.FC<TritonPlayerProps> = ({...props}) => {
	const [loaded, error] = useScript('https://sdk.listenlive.co/web/2.9/td-sdk.min.js');
	const trackingParameters = {};

	return (
		loaded === true && error === false /* && profile != null */ ?
			<TritonPlayer
				{...props}
				trackingParameters={trackingParameters}
			/>
			:
			null
	)
}

import { FC } from "react";

type Props = {
	value: number,
}

export const Progress: FC<Props> = ({ value }) =>
{
	return (
		<div className="progress">
			<div className="progress__rail">
				<div className="progress__track" style={{ width: `${value}%` }}></div>
			</div>
		</div>
	)
}

import { useState, useCallback } from 'react';
import { PlayerState } from '../types/common';

export const usePlayerState = (initialState: PlayerState = PlayerState.Waiting) => {
	const [playerStateProposed, setPlayerStateProposed] = useState<PlayerState>(initialState);
	const [playerState, setPlayerState] = useState<PlayerState>(initialState);

	const transitionStateTo = useCallback((state: PlayerState) => {
		setPlayerState(PlayerState.Waiting);
		setPlayerStateProposed(state);
	}, []);

	return {
		playerState,
		playerStateProposed,
		setPlayerStateProposed,
		setPlayerState,
		transitionStateTo,
	};
};

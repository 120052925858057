import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
	isVisible?: boolean,
	isFixed?: boolean,
}

export const PageHolderInlineArticle: FC<Props> = ({ isVisible, isFixed, children }) => {
	const classes = classNames(
		'page-holder__inline-article',
		isVisible ? 'is-visible' : '',
		isFixed ? 'is-fixed' : '',
	)

	return (
		<div className={classes}>
			{children}
		</div>
	)
}

import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const ThumbsDownIcon: FC<Props> = ({ width = 18, height = 18 }) =>
{
	return (
		<IconWrapper id="thumbs-down" width={width} height={height} nativeWidth={18} nativeHeight={18}>
			<path d="M3.031 2h-1.01v8h1.01V2zm.054 10H0V0h12.825c2.263 0 3.379 1.651 4.574 6.436.978 3.915.883 5.924-1.188 5.564l-.087-.004-5.01.003V18H7.171l-.214-.717c-.732-2.45-1.881-4.113-3.431-5.024L3.085 12zM5.052 2v8.856C6.662 11.972 7.862 13.7 8.663 16h.43v-6h6.869c.003-.61-.164-1.64-.525-3.084C14.447 2.95 13.804 2 12.825 2H5.052z"/>
		</IconWrapper>
	);
};


import { FormattedRelative, FormattedDate } from 'react-intl';

type Props = {
	value: Date
}
export const FormattedRelativeDays = ({ value }: Props) =>
{
	const today = new Date()
	const days = (today.getTime() - value.getTime()) / (1000 * 60 * 60 * 24)

	return (
		days <= 2 ?
			<FormattedRelative
				value={value}
				units="day"
			/>
			:
			<FormattedDate
				value={value}
				year='numeric'
				month='long'
				day='2-digit'
			/>
	)
}
import { FC, ReactNode } from "react";
import classNames from 'classnames';
import { HeaderLogo } from './HeaderLogo';
import { CloseIcon } from '../icons/CloseIcon';
import { AngleDownIcon } from '../icons/AngleDownIcon';

type Props = {
	onClick?: () => void;
	onHover?: () => void;
	onBlur?: () => void;
	isToggled?: boolean,
	logo?: ReactNode,
}

export const HeaderChannelsToggler: FC<Props> = ({ onClick, onHover, onBlur, isToggled, logo }) =>
{
	const classes = classNames(
		'channels-toggler',
		isToggled ? 'is-toggled' : ''
	)

	return (
		<a className={classes} onClick={onClick} onMouseEnter={onHover} onMouseLeave={onBlur}>
			<div className="channels-toggler__logo">
				<HeaderLogo logo={logo} />
			</div>
			<div className="channels-toggler__icon">
				{
					isToggled ? <CloseIcon /> : <AngleDownIcon />
				}
			</div>
		</a>
	)
}

import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
	isOpened?: boolean,
	variant?: 'webs' | 'channels',
}

export const PageHolderSubmenu: FC<Props> = ({ isOpened, variant, children }) => {
	const classes = classNames(
		'page-holder__submenu',
		isOpened ? 'is-opened' : '',
		variant ? `page-holder__submenu--${variant}` : '',
	)

	return (
		<div className={classes}>
			{children}
		</div>
	)
}

import { FC, ReactNode } from "react";
import classNames from 'classnames';
import { Progress } from '../common/Progress'
import { Equalizer } from '../common/Equalizer';
import { PlayIcon } from '../icons/PlayIcon'
import { PauseIcon } from '../icons/PauseIcon'
import { VolumeSlider } from '../controls/VolumeSlider'

type Props = {
	heading?: ReactNode,
	showName?: ReactNode,
	waiting: boolean,
	playing: boolean,
	disabled?: boolean,
	progress?: number,
	volume?: number,
	onVolumeChange?: (volume: number) => void,
	onVolumeMinusClick?: () => void,
	onVolumePlusClick?: () => void,
	onClickStop?: () => void,
	onClickPlay?: () => void,
}

export const RecordPlayerMini: FC<Props> = ({ heading, showName, waiting, playing, disabled, progress, volume, onVolumeChange, onVolumeMinusClick, onVolumePlusClick, onClickPlay, onClickStop }) =>
{
	const playBtnClasses = classNames(
		'play-btn play-btn--sm',
		waiting ? 'is-loading' : '',
		!playing && !waiting ? 'is-pulsating' : '',
		disabled ? 'disabled' : '',
	)

	return (
		<div className="b-header-player b-header-player--compact">
			<div className="b-header-player__content">
				{
					heading != null &&
						<p className="b-header-player__heading">{heading}</p>
				}
				{
					showName != null &&
						<h2 className="b-header-player__title h1">{showName}</h2>
				}
				{
					progress != null &&
						<div className="b-header-player__progress">
							<Progress value={progress} />
						</div>
				}
				{
					playing &&
						<p className="b-header-player__btn">
							{
								!waiting &&
									<span className="b-header-player__equalizer">
										<Equalizer small={true} />
									</span>
							}
							<a className={playBtnClasses} onClick={disabled ? undefined : onClickStop}>
								<PauseIcon />
								<span className="u-vhide">Pozastavit</span>
							</a>
						</p>
				}
				{
					!playing &&
						<p className="b-header-player__btn">
							<a className={playBtnClasses} onClick={onClickPlay}>
								<PlayIcon />
								<span className="u-vhide">Přehrát</span>
							</a>
						</p>
				}
				{
					volume != null &&
						<div className="b-header-player__volume">
							<VolumeSlider value={volume} onChange={onVolumeChange} onMinusClick={onVolumeMinusClick} onPlusClick={onVolumePlusClick} />
						</div>
				}
			</div>
		</div>
	);
}

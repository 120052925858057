import { IconWrapper } from "./IconWrapper";
import { FC } from "react";

type Props = {
	width?: number,
	height?: number,
}

export const MinusIcon: FC<Props> = ({ width = 18, height = 18 }) =>
{
	return (
		<IconWrapper id="minus" width={width} height={height} nativeWidth={18} nativeHeight={18}>
			<path d="M14 9a1 1 0 0 1-1 1H5a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1z"/>
		</IconWrapper>
	);
};

import getConfig from 'next/config';
import { PageHolderInlineArticle } from '../ui/layout/pageHolder/PageHolderInlineArticle';
import { InlineArticle } from '../ui/inlineArticle/InlineArticle';
import { useEffect, useState } from 'react';
import { fetchArticles } from '../../utils/api/fetchArticles';
import { RssItem } from '../../types/rss';
import { ScriptAsync } from './ScriptAsync';

const { publicRuntimeConfig } = getConfig();

type Props = {
	isVisible: boolean;
	isFixed: boolean;
};

type NrcArticle = { url?: string; title?: string; image?: string } | null;

export const RecommendedArticle = ({ isVisible, isFixed }: Props) => {
	const [articles, setArticles] = useState<RssItem[]>([]);
	const [nrcArticle, setNrcArticle] = useState<NrcArticle>(null);
	const [nrcLoadingDone, setNrcLoadingDone] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			const articles = await fetchArticles(publicRuntimeConfig.env.URL_FEED_ARTICLES);
			if (articles && articles.channel && articles.channel.item) {
				setArticles(articles.channel.item);
			}
			if (publicRuntimeConfig.env.URL_NRC) {
				// @ts-ignore
				window._nr = next => {
					if (next.status === 'ok') {
						setNrcArticle(next);
					} else {
						if (process.env.NODE_ENV !== 'production') {
							console.warn(next);
						}
					}
				};
			}
		})();
	}, []);

	let recommendedArticle: NrcArticle = null;

	if (publicRuntimeConfig.env.URL_NRC) {
		recommendedArticle = nrcLoadingDone && nrcArticle ? nrcArticle : null;
	} else {
		const lastArticle = articles[0];
		recommendedArticle = lastArticle && {
			title: lastArticle.title,
			url: lastArticle.link,
			image:
				lastArticle['media:thumbnail'] &&
				lastArticle['media:thumbnail'].$ &&
				lastArticle['media:thumbnail'].$.url
					? lastArticle['media:thumbnail'].$.url
					: undefined,
		};
	}

	return (
		<>
			{recommendedArticle != null && (
				<PageHolderInlineArticle isVisible={isVisible} isFixed={isFixed}>
					<InlineArticle
						href={recommendedArticle.url}
						title={recommendedArticle.title}
						imgSrc={recommendedArticle.image}
					/>
				</PageHolderInlineArticle>
			)}
			{publicRuntimeConfig.env.URL_NRC && (
				<ScriptAsync
					src={publicRuntimeConfig.env.URL_NRC}
					onLoad={() => setNrcLoadingDone( true )}
					onError={() => setNrcLoadingDone( true )}
				/>
			)}
		</>
	);
};

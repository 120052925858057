import React, { FC, SyntheticEvent } from "react";
import { PlayTriangleIcon } from '../icons/PlayTriangleIcon'

type Props = {
	href?: string,
	onClick?: (e: SyntheticEvent) => void,
}

export const BackToPlayer: FC<Props> = ({ href, onClick, children }) =>
{
	return (
		<a href={href} className="back-to-player" onClick={onClick}>
			<span className="back-to-player__icon">
				<PlayTriangleIcon />
			</span>
			<span className="back-to-player__title">
				{children}
			</span>
		</a>
	)
}

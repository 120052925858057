import * as React from 'react';
import classNames from 'classnames';
import { PlayIcon } from '../icons/PlayIcon';
import { PauseIcon } from '../icons/PauseIcon';
import { Progress } from '../common/Progress';
import { Equalizer } from '../common/Equalizer';
import { VolumeSlider } from '../controls/VolumeSlider';

type Props = {
	heading?: React.ReactNode,
	showName?: React.ReactNode,
	showTime?: React.ReactNode,
	backLink?: React.ReactNode,
	moderatorMotiveURL?: string,
	moderatorName?: string,
	waiting: boolean,
	playing: boolean,
	progress?: number,
	volume: number,
	onVolumeChange?: (volume: number) => void,
	onVolumeMinusClick?: () => void,
	onVolumePlusClick?: () => void,
	onClickStop?: () => void,
	onClickPlay?: () => void,
	compactView?: boolean,
	programUrl?: string,
}

export const HeaderPlayer: React.FunctionComponent<Props> = ({ heading, showName, showTime, backLink, waiting, playing, moderatorMotiveURL, moderatorName, progress, volume, onVolumeChange, onVolumeMinusClick, onVolumePlusClick, onClickStop, onClickPlay, compactView, programUrl }) =>
{
	const classes = classNames(
		'b-header-player',
		compactView ? 'b-header-player--compact' : '',
	)

	const playBtnClasses = classNames(
		'play-btn',
		compactView ? 'play-btn--sm' : '',
		waiting ? 'is-loading' : '',
		!playing && !waiting ? 'is-pulsating' : '',
	)

	return (
		<div className={classes}>
			<div className="b-header-player__content">
				{
					heading != null &&
						<p className="b-header-player__heading">
							{
								programUrl ?
									<a className="u-color-red" href={programUrl} target="_blank" rel="noopener">{heading}</a>
									:
									<span className="u-color-red">{heading}</span>
							}
							{
								moderatorName != null &&
									<span className="b-header-player__moderator"> - {moderatorName}</span>
							}
						</p>
				}
				{
					showName != null &&
						<h2 className="b-header-player__title h1">
							{
								programUrl ?
									<a className="u-color-black" href={programUrl} target="_blank" rel="noopener">{showName}</a>
									:
									<span className="u-color-black">{showName}</span>
							}
						</h2>
				}
				{
					showTime != null &&
						<p className="b-header-player__meta">{showTime}</p>
				}
				{
					progress != null &&
						<div className="b-header-player__progress">
							<Progress value={progress} />
						</div>
				}
				{
					backLink != null && backLink
				}
				{
					playing &&
						<p className="b-header-player__btn">
							{
								!waiting &&
									<span className="b-header-player__equalizer">
										<Equalizer small={compactView} />
									</span>
							}
							<a className={playBtnClasses} onClick={onClickStop}>
								<PauseIcon />
								<span className="u-vhide">Pozastavit</span>
							</a>
						</p>
				}
				{
					!playing &&
						<p className="b-header-player__btn">
							<a className={playBtnClasses} onClick={onClickPlay}>
								<PlayIcon />
								<span className="u-vhide">Přehrát</span>
							</a>
						</p>
				}
				<div className="b-header-player__volume">
					<VolumeSlider value={volume} onChange={onVolumeChange} onMinusClick={onVolumeMinusClick} onPlusClick={onVolumePlusClick} />
				</div>
			</div>
			{
				moderatorMotiveURL &&
					<div className="b-header-player__bg" style={{
						backgroundImage: `url(${moderatorMotiveURL})`,
					}} />
			}
		</div>
	);
}

import { FC } from "react";

type Props = {}

export const FooterCopyright: FC<Props> = ({ children }) =>
{
	return (
		<p className="footer__copy">{children}</p>
	)
}

import classNames from 'classnames';
import { VOTE_DOWN, VOTE_UP } from "../../../constants";
import { Vote } from "../../../types/e2api";
import { ThumbsDownIcon } from '../icons/ThumbsDownIcon';
import { ThumbsUpIcon } from '../icons/ThumbsUpIcon';

type Props = {
	artist: string | undefined | null,
	cover: string | undefined | null,
	title?: string | undefined | null,
	radioCode?: string | undefined | null,
	voted?: Vote | undefined | null,
	onVote: (radioCode: string | undefined | null, artist: string | undefined | null, title: string | undefined | null, vote: Vote) => void
}


export const NowPlaying = ({ artist, cover, title, radioCode, voted, onVote }: Props) =>
{
	const isVotedUp = voted === VOTE_UP;
	const voteUpClasses = classNames(
		'b-now-playing__vote b-now-playing__vote--up',
		isVotedUp ? 'b-now-playing__vote--voted' : ''
	)
	const voteUpTooltipClasses = classNames(
		'b-now-playing__tooltip b-now-playing__tooltip--up',
		isVotedUp ? 'b-now-playing__tooltip--voted' : ''
	)
	const isVotedDown = voted === VOTE_DOWN;
	const voteDownClasses = classNames(
		'b-now-playing__vote b-now-playing__vote--down',
		isVotedDown ? 'b-now-playing__vote--voted' : ''
	)
	const voteDownTooltipClasses = classNames(
		'b-now-playing__tooltip b-now-playing__tooltip--down',
		isVotedDown ? 'b-now-playing__tooltip--voted' : ''
	)

	return (
		<div className="b-now-playing">
			<figure className="b-now-playing__img">
				{
					cover &&
						<img src={cover} alt="" />
				}
			</figure>
			{
				artist &&
					<p className="b-now-playing__artist u-text-uppercase">{artist}</p>
			}
			{
				title &&
					<p className="b-now-playing__title">{title}</p>
			}
			<span className={voteUpClasses} onClick={() => onVote(radioCode, artist, title, VOTE_UP)}>
				<span className={voteUpTooltipClasses}>
					Díky! Pomáháš nám tvořit playlist.
				</span>

				<ThumbsUpIcon />
				<span className="u-vhide">Líbí se mi</span>
			</span>
			<span className={voteDownClasses} onClick={() => onVote(radioCode, artist, title, VOTE_DOWN)}>
				<span className={voteDownTooltipClasses}>
					Díky! Pomáháš nám tvořit playlist.
				</span>

				<ThumbsDownIcon />
				<span className="u-vhide">Nelíbí se mi</span>
			</span>
		</div>
	)
}



import React, { FC } from 'react';
import { MediaWrap } from '../ui/mediaWrap/MediaWrap';
import { MediaWrapBtn } from '../ui/mediaWrap/MediaWrapBtn';
import { MediaWrapHeading } from '../ui/mediaWrap/MediaWrapHeading';
import { PlayList } from '../ui/playlist/PlayList';
import { PlayListItem } from '../ui/playlist/PlayListItem';
import { AnchorButton } from '../ui/controls/AnchorButton';
import { HistoryItem } from '../../types/common';
import { FormattedTime } from 'react-intl';


type Props = {
	history: HistoryItem[],
	linkToPlaylist?: string,
	onClickSong?: () => void,
}

export const HistoryPlaylist: FC<Props> = React.memo(({ history, linkToPlaylist, onClickSong }) =>
{
	return (
		history.length > 0 ?
			<MediaWrap>
				<MediaWrapHeading>Playlist</MediaWrapHeading>
					<PlayList>
						{
							history.map(item =>
								<PlayListItem
									key={item.mediaId + '-' + item.songStart}
									item={item}
									href={`https://www.youradio.cz/?song=${item.yrSongId}`}
									onClick={onClickSong}
									played={
										item.songStart != null ?
											<>
												Hrálo
												{' '}
												<FormattedTime
													value={item.songStart}
												/>
											</>
											:
											null
									}
								/>
							)
						}
					</PlayList>
					{
						linkToPlaylist &&
							<MediaWrapBtn>
								<AnchorButton
									// href="https://www.evropa2.cz/playlist"
									href={linkToPlaylist}
									target="_blank"
									rel="noopener"
								>
									Celý playlist
								</AnchorButton>
							</MediaWrapBtn>
					}
			</MediaWrap>
			:
			null
	);
});

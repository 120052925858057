import { parseZonedTime } from 'timezone-support/dist/parse-format';
import { findTimeZone, setTimeZone, convertTimeToDate } from 'timezone-support/dist/lookup-convert';
import { populateTimeZones } from 'timezone-support/dist/lookup-convert';
import data from 'timezone-support/dist/data-1970-2038';
// import { parsePlaylistDate } from './berlin';

populateTimeZones(data);

const berlin = findTimeZone('Europe/Berlin');
const format = 'YYYY-MM-DD HH:mm:ss';

export const parsePlaylistDate = (input: string | null): Date | null => {
	if(input) {
		const time = parseZonedTime(input, format);
		const berlinTime = setTimeZone(time, berlin);
		return convertTimeToDate(berlinTime);
	}
	return null;
};

export const dateToISOString = (date: Date | null) => {
	if(date) return date.toISOString();
	else return null;
}

export const SITE_NAME = 'onair'

// export const CDN_URLS = process.env.NODE_ENV === 'production' ?
// 	{
// 		img: 'https://img.static.lagardere.cz/e2', // -> https://pre.evropa2.cz/static/img/
// 		css: 'https://css.static.lagardere.cz/e2', // -> https://pre.evropa2.cz/static/css/
// 		js: 'https://js.static.lagardere.cz/e2', // -> https://pre.evropa2.cz/static/js/
// 		font: 'https://m.static.lagardere.cz/e2/font', // -> https://pre.evropa2.cz/static/font/
// 		favicon: 'https://m.static.lagardere.cz/e2/favicon', // -> https://pre.evropa2.cz/static/favicon/
// 	}
// 	:
// 	{
// 		img: '/static/img',
// 		css: '/static/css',
// 		js: '/static/js',
// 		font: '/static/font',
// 		favicon: '/static/favicon'
// 	}

export const CDN_URLS =	{
	img: '/static/images',
	css: '/static/css',
	js: '/static/js',
	font: '/static/font',
	favicon: '/static/favicon'
}


export const VOTE_UP = 1;
export const VOTE_DOWN = 0;

export const DEFAULT_CHANNEL = 'fm';
export const DEFAULT_RADIO_CODE = 'evropa2';

export const VOLUME_INCREMENT = 5;

export const defaultBrandingAdData = {
	paddingTop: 140,
	color: 'aeedff',
	top: `/static/images/bg/default.jpg`,
	second: null,
	topHeight: 1047,
	backgroundSize: '100% auto'
}

export const PLAYLIST_ITEMS_COUNT = 27;

export const USE_TRITON_PLAYER = true;

export const URL_PROGRAM = 'https://www.evropa2.cz/program';
export const URL_ARCHIVE = 'https://www.evropa2.cz/porady-zaznamy/';


export const AD_UNITS = {
	INTERSCROLLER: 'evropa2.cz_Interscroller',
};

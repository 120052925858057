import fetch from 'isomorphic-fetch';
import { Rss, RssWrapper } from '../../types/rss';
import { parseXml } from './parseXml';

export const fetchRecords = async (url: string): Promise<Rss | null> => {
	try {
		const response = await fetch(url);
		const xml = await response.text();
		const json = await parseXml<RssWrapper>(xml);
		return json.rss;
	}
	catch (e) {
		// console.error(e);
		return null;
	}
};



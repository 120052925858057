import { NextPageContext } from 'next';
import getConfig from 'next/config';
import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { animateScroll } from 'react-scroll';
import { Archive } from '../../../common/src/components/layout/Archive';
import { BaseLayout } from '../../../common/src/components/layout/BaseLayout';
import { ChannelsMenu } from '../../../common/src/components/layout/ChannelsMenu';
import { RecordPlayerBox } from '../../../common/src/components/recordPlayer/RecordPlayerBox';
import { Header } from '../../../common/src/components/ui/layout/Header';
import { Main } from '../../../common/src/components/ui/layout/Main';
import { PageHolder } from '../../../common/src/components/ui/layout/pageHolder/PageHolder';
import { PageHolderMain } from '../../../common/src/components/ui/layout/pageHolder/PageHolderMain';
import { PageHolderSubmenu } from '../../../common/src/components/ui/layout/pageHolder/PageHolderSubmenu';
import { DEFAULT_CHANNEL } from '../../../common/src/constants';
import bgDefault from '../../public/static/images/bg/default.jpg';
import { Channel, HistoryItem, Metadata, PlayerState } from '../../../common/src/types/common';
import { Vote } from '../../../common/src/types/e2api';
import { Rss, RssItem } from '../../../common/src/types/rss';
import detectHover from 'detect-hover';
import { fetchRecord } from '../../../common/src/utils/api/fetchRecord';
import { fetchRecords } from '../../../common/src/utils/api/fetchRecords';
import { fetchShowRecords } from '../../../common/src/utils/api/fetchShowRecords';
import { fetchShows } from '../../../common/src/utils/api/fetchShows';
import { getRadioCodeFromChannels } from '../../../common/src/utils/getRadioCodeFromChannels';
import { Waypoint } from 'react-waypoint';
import { PageHolderSidebar } from '../../../common/src/components/ui/layout/pageHolder/PageHolderSidebar';
import { Sidebar } from '../../../common/src/components/ui/layout/Sidebar';
import { HeaderPlayer } from '../../../common/src/components/ui/player/HeaderPlayer';
import { computeShowProgress } from '../../../common/src/utils/computeShowProgress';
import { RecordPlayerMini } from '../../../common/src/components/ui/player/RecordPlayerMini';
import { MainContentRow } from '../../../common/src/components/ui/layout/MainContentRow';
import { MainContentColumn } from '../../../common/src/components/ui/layout/MainContentColumn';
import { findNextRecord } from '../../../common/src/utils/findNextRecord';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { RowMain } from '../../../common/src/components/ui/layout/RowMain';
import { getShowName } from '../../../common/src/utils/getShowName';
import { HistoryPlaylist } from '../../../common/src/components/laplayer/HistoryPlaylist';
import { BackToPlayer } from '../../../common/src/components/ui/common/BackToPlayer';
import { makeRouterParams } from '../../../common/src/utils/makeRouteParams';
import { StateContext } from '../../../common/src/components/ads/StateProvider';
import { ShowOnTabletUp } from '../../../common/src/components/ui/layout/ShowOnTabletUp';
import { ShowOnTabletDown } from '../../../common/src/components/ui/layout/ShowOnTabletDown';
import { LegacyBrowserBox } from '../../../common/src/components/service/LegacyBrowserBox';
import { RecommendedArticle } from '../../../common/src/components/service/RecommendedArticle';
import { CookieConsentBox } from '../../../common/src/components/service/CookieConsentBox';
import { PlayerEngineCombined } from '../../../common/src/components/service/PlayerEngineCombined';
import { HeaderTabs } from '../../../common/src/components/layout/HeaderTabs';
import { getTitleFromMetadata } from '../../../common/src/utils/getTitleFromMetadata';
import { getRecentShowsItems } from '../../../common/src/utils/getRecentShowsItems';
import { NowPlayingBox } from '../../../common/src/components/layout/NowPlayingBox';
import { useVolume } from '../../../common/src/hooks/useVolume';
import { updateShowInLocalStorage } from '../../../common/src/utils/api/updateShowInLocalStorage';
import { useSideMenu } from '../../../common/src/hooks/useSideMenu';
import { usePlayerState } from '../../../common/src/hooks/usePlayerState';
import { LogoFrekvence1 } from '../../../common/src/components/ui/icons/LogoFrekvence1';
import { DEFAULT_RADIO_CODE_FREKVENCE1, URL_PROGRAM_FREKVENCE1,URL_ARCHIVE_FREKVENCE1, AD_RULES_URL, GDPR_URL } from '../../constants';
import Link from 'next/link';
import Router from 'next/router';
import { filterNullProps } from '../../../common/src/utils/filterNullProps';
import { NextProgramFromRds } from '../../../common/src/components/program/NextProgramFromRds';
import { Footer } from '../../../common/src/components/layout/Footer';
import NextSeo from 'next-seo';

const { publicRuntimeConfig } = getConfig();

const useTritonPlayer = true;

const carodaUrl =
	'https://publisher.caroda.io/videoPlayer/caroda.min.js?vcid=vcze4a2003ab7e5f0&ctok=0c0cfc4712474574230949a3fba94c87f4cdc0';

type Query = {
	archive?: string;
	show?: string;
	channel?: string;
	radioCode?: string;
	play?: string;
};

type Props = {
	url: {
		query: Query;
	};
	feedRecords: Rss | null;
	feedShows: Rss | null;
	showRecords: Rss | null;
	playRecord?: RssItem;
	siteUrl?: string;
};

const Homepage = (props: Props) => {
	const [history, setHistory] = useState<HistoryItem[]>([]);
	const [metadata, setMetadata] = useState<Metadata | null>(null);
	const [channels, setChannels] = useState<Channel[] | null>(null);
	const [showLatestArticle, setShowLatestArticle] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [recordPlayerState, setRecordPlayerState] = useState<PlayerState>(PlayerState.Stopped);
	const [showsLastItemIds, setShowsLastItemIds] = useState<{ [key: string]: string } | undefined>(undefined);
	const [isWindowLoaded, setIsWindowLoaded] = useState(false);
	const [voted, setVoted] = useState<Vote | undefined | null>(null);
	const [recommendedArticleFixed, setRecommendedArticleFixed] = useState(true);
	const { showChannels, toggleChannels, openChannels, closeChannels, toggleMenu } = useSideMenu();

	const {
		playerState: acPlayerState,
		playerStateProposed: acPlayerStateProposed,
		setPlayerState: setAcPlayerState,
		setPlayerStateProposed: setAcPlayerStateProposed,
		transitionStateTo: transitionAcPlayerStateTo,
	} = usePlayerState();

	const {
		playerState: tritonPlayerState,
		playerStateProposed: tritonPlayerStateProposed,
		setPlayerState: setTritonPlayerState,
		setPlayerStateProposed: setTritonPlayerStateProposed,
		transitionStateTo: transitionTritonPlayerStateTo,
	} = usePlayerState();

	const {
		volume: playerVolume,
		setVolume: onVolumeChange,
		incrementVolume: onVolumePlusClick,
		decrementVolume: onVolumeMinusClick,
	} = useVolume();

	const {
		volume: recordPlayerVolume,
		setVolume: onRecordVolumeChange,
		incrementVolume: onRecordVolumePlusClick,
		decrementVolume: onRecordVolumeMinusClick,
	} = useVolume();

	const refMain = useRef<HTMLElement>(null);

	const { transparentBackground } = useContext(StateContext);

	const { feedRecords, feedShows, showRecords, playRecord, siteUrl } = props;
	const { channel, archive, show, play } = props.url.query;
	const urlCode = channel || DEFAULT_CHANNEL;
	const radioCode = getRadioCodeFromChannels(channels || [], urlCode) || DEFAULT_RADIO_CODE_FREKVENCE1;
	const showName = getShowName(feedShows)(show);
	const playRecordShowName = getShowName(feedShows)(playRecord ? playRecord.show : null);

	const progress =
		metadata && metadata.showStart && metadata.showEnd
			? computeShowProgress(new Date(), metadata.showStart, metadata.showEnd)
			: undefined;

	// const brandingAdUnit = 'evropa2.cz_BG';
	// const showAds = true;

	const isTritonPlayerActive = useTritonPlayer && urlCode === DEFAULT_CHANNEL;
	const station = 'FREKVENCE1';

	const waiting = isTritonPlayerActive
		? tritonPlayerState === PlayerState.Waiting
		: acPlayerState === PlayerState.Waiting;
	const playing = isTritonPlayerActive
		? tritonPlayerState === PlayerState.Playing
		: acPlayerState === PlayerState.Playing;

	const NowPlayingBox2 = useCallback(
		() => (
			<NowPlayingBox
				radioCode={radioCode}
				metadata={metadata}
				voted={voted}
				onClickVote={(voted: Vote) => setVoted(voted)}
			/>
		),
		[radioCode, metadata, voted]
	);

	const onEnterTopWaypoint = useCallback(() => setShowLatestArticle(false), []);
	const onLeaveTopWaypoint = useCallback(() => setShowLatestArticle(true), []);

	const onEnterMainWaypoint = useCallback(() => setShowSidebar(false), []);
	const onLeaveMainWaypoint = useCallback(
		({ currentPosition }: Waypoint.CallbackArgs) => setShowSidebar(currentPosition === Waypoint.above),
		[]
	);

	const onEnterFooterEndWaypoint = useCallback(() => setRecommendedArticleFixed(false), []);
	const onLeaveFooterEndWaypoint = useCallback(() => setRecommendedArticleFixed(true), []);

	const onHistoryLoaded = useCallback((history: any) => setHistory(history), []);

	const onChannelsLoaded = useCallback((channels: Channel[]) => setChannels(channels), []);

	const onUpdateMetadata = useCallback((metadata: Metadata) => {
		console.log('update metadata', metadata);
		let authors = [];
		if (authors.indexOf(",") > 0) {
			authors.push(metadata.moderatorName.split(', '));
		} else if (metadata.moderatorName != null) {
			authors.push(metadata.moderatorName);
		} else {
			authors.push("Automat");
		}
		setMetadata(metadata);
		/*if (permutive) {
			permutive.addon('web', {
				page: {
					type: "article",
					site: "Activeradio/online.frekvence1.cz",
					article: {
						title: metadata.showName,
						authors: authors
					}
				}
			});
		}*/
	}, []);

	const scrollToMain = useCallback(() => {
		if (refMain.current) {
			animateScroll.scrollTo(refMain.current.offsetTop, {
				behavior: 'smooth',
			});
		}
	}, []);

	const onAcPlayerStateChange = useCallback((state: PlayerState) => {
		setAcPlayerState(state);
	}, []);

	const onTritonPlayerStateChange = useCallback((state: PlayerState) => {
		setTritonPlayerState(state);
	}, []);

	const onRecordPlayerStateChange = useCallback((state: PlayerState) => {
		setRecordPlayerState((prevRecordPlayerState) =>
			state === PlayerState.Stopped && prevRecordPlayerState === PlayerState.AdBreakStarted
				? prevRecordPlayerState
				: state
		);
	}, []);

	const onRecordEnd = useCallback(() => {
		if (archive === 'porady' && show != null && showRecords != null && play != null) {
			const nextPlay = findNextRecord(showRecords, play);
			if (nextPlay) {
				Router.push({
					pathname: '/',
					query: filterNullProps({ channel, archive, show, play: nextPlay.idEpisode }),
				});
			}
		}

		if (archive !== 'porady' && feedRecords != null && play != null) {
			const nextPlay = findNextRecord(feedRecords, play);
			if (nextPlay) {
				Router.push({
					pathname: '/',
					query: filterNullProps({ channel, archive, play: nextPlay.idEpisode }),
				});
			}
		}
	}, [archive, show, showRecords, play, feedRecords]);

	const transitionPlayerStateTo =
		useTritonPlayer && urlCode === DEFAULT_CHANNEL ? transitionTritonPlayerStateTo : transitionAcPlayerStateTo;

	const onClickStop = useCallback(() => {
		if (playing) {
			transitionPlayerStateTo(PlayerState.Stopped);

			if (window.player) {
				window.player.programEvent(1, 0, 'stop');
			}
		}
	}, [useTritonPlayer, urlCode, playing]);

	const onClickPlay = useCallback(() => {
		transitionPlayerStateTo(PlayerState.Playing);

		if (window.player) {
			window.player.programEvent(1, 0, 'play');
		}
	}, [useTritonPlayer, urlCode]);

	// DidMount effect
	useEffect(() => {
		const windowLoad = () => {
			setTimeout(() => {
				if (isWindowLoaded === false) {
					setIsWindowLoaded(true);
				}
			}, 50);
		};

		const showsLastItemIds = getRecentShowsItems('e2UniplayerShows');
		setShowsLastItemIds(showsLastItemIds);

		window.addEventListener('load', windowLoad, false);

		if (document.readyState === 'complete') windowLoad();

		if (detectHover.hover === true) {
			const htmlElement = document.querySelector('html');
			if (htmlElement) htmlElement.classList.add('no-touch');
		}

		return () => {
			window.removeEventListener('load', windowLoad, false);
		};
	}, []);

	useEffect(() => {
		setRecordPlayerState(PlayerState.Waiting);
		if (play) {
			onClickStop();
		} else {
			onClickPlay();
		}
	}, [play]);

	useEffect(() => {
		setTritonPlayerState(PlayerState.Waiting);
		setTritonPlayerStateProposed(PlayerState.Waiting);
	}, [channel]);

	useEffect(() => {
		setVoted(null);
	}, [metadata != null ? metadata.title : undefined]);

	useEffect(() => {
		if (show != null && feedShows != null) {
			const showsLastItemIds = updateShowInLocalStorage(show, feedShows);
			setShowsLastItemIds(showsLastItemIds);
		}
	}, [show, feedShows]);

	const siteName = 'Frekvence 1 ON-AIR';
	const title = getTitleFromMetadata(metadata, play, playRecord, playRecordShowName, siteName);
	const description = 'Přehrávač Frekvence 1. Poslouchej Frekvenci 1 přes internet :-)';

	return (
		<BaseLayout isWindowLoaded={isWindowLoaded} author="Frekvence 1">
			<NextSeo
				config={{
					title: title,
					description: description,
					canonical: siteUrl,
					openGraph: {
						url: siteUrl,
						title: 'Rádio Frekvence 1 ON-AIR',
						description: description,
						type: 'musician',
						images: [
							{
								url: `${publicRuntimeConfig.env.URL_CDN_IMG}/og-f1-onair.png`,
								width: 1200,
								height: 628,
								alt: 'Frekvence 1 VYSÍLÁNÍ',
							},
						],
						site_name: siteName,
					},
				}}
			/>
			<Waypoint onEnter={onEnterTopWaypoint} onLeave={onLeaveTopWaypoint} />
			<PageHolder bgImage={bgDefault} theme="frekvence1">
				{/* GPT */}
				{/* <ErrorBoundary>
					<AdSlotBranding adUnit={brandingAdUnit} sizes={[[1, 1]]} />
				</ErrorBoundary> */}
				<RowMain>
					<PageHolderMain>
						<PlayerEngineCombined
							urlCode={urlCode}
							radioCode={radioCode}
							defaultRadioCode={DEFAULT_RADIO_CODE_FREKVENCE1}
							defaultChannel={DEFAULT_CHANNEL}
							onHistoryLoaded={onHistoryLoaded}
							onUpdateMetadata={onUpdateMetadata}
							onChannelsLoaded={onChannelsLoaded}
							isTritonPlayerActive={isTritonPlayerActive}
							onAcPlayerStateChange={onAcPlayerStateChange}
							setAcPlayerState={setAcPlayerStateProposed}
							acPlayerStateProposed={acPlayerStateProposed}
							acPlayerState={acPlayerState}
							tritonPlayerStateProposed={tritonPlayerStateProposed}
							onTritonPlayerStateChange={onTritonPlayerStateChange}
							progress={progress}
							playerVolume={playerVolume}
							onVolumeChange={onVolumeChange}
							onVolumeMinusClick={onVolumeMinusClick}
							onVolumePlusClick={onVolumePlusClick}
							metadata={metadata}
							station={station}
							carodaUrl={carodaUrl}
						/>
						<Header
							hasSolidBg={!transparentBackground}
							onMenuClick={toggleMenu}
							onChannelsTogglerClick={toggleChannels}
							onChannelsOpen={openChannels}
							onChannelsClose={closeChannels}
							isChannelsTogglerOpened={showChannels}
							hasVideoInside={play != null}
							logo={<LogoFrekvence1 />}
							slotTabs={
								<HeaderTabs
									urlCode={urlCode}
									play={play}
									archive={archive}
									scrollToMain={scrollToMain}
								/>
							}
							slotLeft={
								metadata != null && (
									<ShowOnTabletUp>
										<NowPlayingBox2 />
									</ShowOnTabletUp>
								)
							}
							slotCenter={
								<TransitionGroup>
									{play ? (
										<CSSTransition
											key="record-player"
											classNames="player-wrapper player"
											timeout={500}
										>
											<RecordPlayerBox
												archive={archive}
												show={show}
												play={play}
												channel={urlCode}
												playRecord={playRecord}
												state={recordPlayerState}
												onStateChange={onRecordPlayerStateChange}
												volume={recordPlayerVolume}
												siteUrl={siteUrl}
												onRecordEnd={onRecordEnd}
												carodaUrl={carodaUrl}
											/>
										</CSSTransition>
									) : (
										<CSSTransition
											key={'la-player' + urlCode}
											classNames="player-wrapper player"
											timeout={500}
										>
											<div>
												<HeaderPlayer
													heading={urlCode === DEFAULT_CHANNEL ? `Živě` : 'Webrádio'}
													backLink={
														urlCode !== DEFAULT_CHANNEL &&
														progress == null && (
															<Link
																href={{
																	pathname: '/',
																	query: makeRouterParams(
																		{ archive, show },
																		{ channel: DEFAULT_CHANNEL }
																	),
																}}
																passHref
																shallow
															>
																<BackToPlayer>Zpět na živé vysílání</BackToPlayer>
															</Link>
														)
													}
													showName={metadata ? metadata.showName || metadata.name : undefined}
													showTime={
														metadata && metadata.showStart && metadata.showEnd
															? `${metadata.showStart}–${metadata.showEnd}`
															: null
													}
													waiting={waiting}
													playing={playing}
													moderatorMotiveURL={
														metadata && metadata.moderatorMotiveURL
															? metadata.moderatorMotiveURL
															: undefined
													}
													moderatorName={
														metadata && metadata.moderatorName
															? metadata.moderatorName
															: undefined
													}
													onClickStop={onClickStop}
													onClickPlay={onClickPlay}
													progress={progress}
													volume={playerVolume}
													onVolumeChange={onVolumeChange}
													onVolumeMinusClick={onVolumeMinusClick}
													onVolumePlusClick={onVolumePlusClick}
													programUrl={URL_PROGRAM_FREKVENCE1}
												/>
												{metadata != null && (
													<ShowOnTabletDown>
														<NowPlayingBox2 />
													</ShowOnTabletDown>
												)}
											</div>
										</CSSTransition>
									)}
								</TransitionGroup>
							}
							slotRight={
								<TransitionGroup>
									{metadata != null && urlCode === DEFAULT_CHANNEL && (
										<CSSTransition
											key="next-program"
											classNames="player-wrapper player"
											timeout={500}
										>
											<NextProgramFromRds
												url="https://cms.frekvence1.cz/wd-scripts/api/rds.json"
												metadata={metadata}
												programUrl={URL_PROGRAM_FREKVENCE1}
											/>
										</CSSTransition>
									)}
								</TransitionGroup>
							}
						></Header>
						<Waypoint onEnter={onEnterMainWaypoint} onLeave={onLeaveMainWaypoint} />
						<Main hasSolidBg={!transparentBackground} ref={refMain}>
							<MainContentRow>
								<MainContentColumn hideOnMobile={archive != null}>
									<HistoryPlaylist
										history={history}
										linkToPlaylist="https://www.frekvence1.cz/co-hrajeme"
										onClickSong={onClickStop}
									/>
								</MainContentColumn>
								<MainContentColumn hideOnMobile={archive == null}>
									<Archive
										urlCode={urlCode}
										archive={archive}
										feedRecords={feedRecords}
										show={show}
										showRecords={showRecords}
										feedShows={feedShows}
										showName={showName}
										play={play}
										archiveUrl={URL_ARCHIVE_FREKVENCE1}
										showsLastItemIds={showsLastItemIds}
									/>
								</MainContentColumn>
							</MainContentRow>
							<Footer adUrl={AD_RULES_URL} gdprUrl={GDPR_URL} />
							<Waypoint onEnter={onEnterFooterEndWaypoint} onLeave={onLeaveFooterEndWaypoint} />
						</Main>
						<PageHolderSubmenu isOpened={showChannels} variant="channels">
							<ChannelsMenu
								channels={channels}
								onChannelsToggle={toggleChannels}
								onChannelsOpen={openChannels}
								onChannelsClose={closeChannels}
								activeChannelUrlCode={urlCode}
							/>
						</PageHolderSubmenu>
						{/* TODO: nezobrazovat dokud nebude pripraveno <PageHolderSubmenu isOpened={showMenu} variant="webs">
							<WebsMenu
								onMenuToggle={this.onMenuToggle}
							/>
						</PageHolderSubmenu>*/}
						<PageHolderSidebar isOpened={showSidebar}>
							<Sidebar>
								{play ? (
									playRecord != null && (
										<RecordPlayerMini
											heading="Záznam"
											showName={playRecord.title}
											waiting={recordPlayerState === PlayerState.Waiting}
											playing={
												recordPlayerState === PlayerState.Playing ||
												recordPlayerState === PlayerState.AdBreakStarted
											}
											disabled={recordPlayerState === PlayerState.AdBreakStarted}
											volume={recordPlayerVolume}
											onVolumeChange={onRecordVolumeChange}
											onVolumeMinusClick={onRecordVolumeMinusClick}
											onVolumePlusClick={onRecordVolumePlusClick}
											onClickPlay={() => {
												if (window.activeCarodaPlayer) {
													window.activeCarodaPlayer.play();
												}
											}}
											onClickStop={() => {
												if (window.activeCarodaPlayer) {
													window.activeCarodaPlayer.pause();
												}
											}}
										/>
									)
								) : (
									<HeaderPlayer
										heading={urlCode === DEFAULT_CHANNEL ? 'Živě' : 'Webrádio'}
										backLink={
											urlCode !== DEFAULT_CHANNEL &&
											progress == null && (
												<Link
													href={{
														pathname: '/',
														query: makeRouterParams(
															{ archive, show },
															{ channel: DEFAULT_CHANNEL }
														),
													}}
													passHref
													shallow
												>
													<BackToPlayer>Zpět na živé vysílání</BackToPlayer>
												</Link>
											)
										}
										showName={metadata ? metadata.showName || metadata.name : undefined}
										showTime={
											metadata && metadata.showStart && metadata.showEnd
												? `${metadata.showStart}–${metadata.showEnd}`
												: null
										}
										waiting={waiting}
										playing={playing}
										moderatorMotiveURL={
											metadata && metadata.moderatorMotiveURL
												? metadata.moderatorMotiveURL
												: undefined
										}
										onClickStop={onClickStop}
										onClickPlay={onClickPlay}
										progress={progress}
										compactView={true}
										volume={playerVolume}
										onVolumeChange={onVolumeChange}
										onVolumeMinusClick={onVolumeMinusClick}
										onVolumePlusClick={onVolumePlusClick}
										programUrl={URL_PROGRAM_FREKVENCE1}
									/>
								)}
								{metadata != null && <NowPlayingBox2 />}
							</Sidebar>
						</PageHolderSidebar>
						<RecommendedArticle isVisible={showLatestArticle === true} isFixed={recommendedArticleFixed} />
					</PageHolderMain>
				</RowMain>
				<CookieConsentBox moreInfoUrl={GDPR_URL} />
			</PageHolder>
			<LegacyBrowserBox />
		</BaseLayout>
	);
};

Homepage.getInitialProps = async (ctx: NextPageContext) => {
	const { query } = ctx;

	const [feedRecords, feedShows, showRecords, playRecord] = await Promise.all([
		fetchRecords(publicRuntimeConfig.env.URL_FEED_RECORDS),
		fetchShows(publicRuntimeConfig.env.URL_FEED_SHOWS),
		query.archive === 'porady' && query.show != null && typeof query.show === 'string'
			? fetchShowRecords(publicRuntimeConfig.env.URL_FEED_SHOW, query.show)
			: Promise.resolve(null),
		query.play != null && typeof query.play === 'string'
			? fetchRecord(publicRuntimeConfig.env.URL_FEED_RECORD, query.play)
			: Promise.resolve(null),
	]);

	return { feedRecords, feedShows, showRecords, playRecord };
};

export default Homepage;

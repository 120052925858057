export const getRecentShowsItems = (
	storageKey: string
): {
	[key: string]: string;
} => {
	try {
		const recentShowsItemsStringified = window.localStorage.getItem(storageKey) || '{}';
		const recentShowsItems: {
			[key: string]: string;
		} = JSON.parse(recentShowsItemsStringified) || {};
		if (typeof recentShowsItems !== 'object') return {};
		return recentShowsItems;
	} catch (e) {
		return {};
	}
};

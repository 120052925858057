import * as React from 'react';
import { Component } from 'react';
// @ts-ignore
import { Link } from '../../../../evropa2/src/server/routes';
import { HistoryItem, Metadata, Channel, PlayerState } from '../../types/common';
import { PLAYLIST_ITEMS_COUNT } from '../../constants';
import entities from "entities";
import { parsePlaylistDate, dateToISOString } from './parsePlaylistDate';

let laPlayerInitialized: boolean = false;

type Props = {
	urlCode: string,
	radioCode?: string,
	defaultRadioCode: string,
	defaultChannel: string,
	onHistoryLoaded: (history: HistoryItem[]) => void,
	updateMetadata: (metadata: Metadata) => void,
	onChannelsLoaded?: (channels: Channel[]) => void,
	onStateChange?: (state: PlayerState) => void,
	setPlayerState?: (state: PlayerState) => void,
	playerState?: PlayerState,
	proposedState?: PlayerState,
	progress?: number,
	volume?: number,
	onVolumeChange?: (volume: number) => void,
	onVolumeMinusClick?: () => void,
	onVolumePlusClick?: () => void,
	autoplay: boolean,
	noplay: boolean,
}

export class LaPlayer extends Component<Props>
{
	adDivRef = React.createRef<HTMLDivElement>()

	oldAcpcb: any

	canAutoPlayAudio = false

	constructor(props: Props)
	{
		super(props);
	}

	render()
	{
		return (
			<div>
				<div
					ref={this.adDivRef}
					id="addiv"
					style={{
						position: "absolute",
						left: 300,
						zIndex: 100,
					}}>
				</div>
			</div>
		);
	}

	async componentDidMount()
	{
		this.oldAcpcb = window.acpcb;

		window.acpcb = {
			...(window.acpcb || {}),
			updateMetadata: this.updateMetadata,
			channelsLoaded: this.onChannelsLoaded,
			historyLoaded: this.onHistoryLoaded,
			initDone: this.onInitDone,
		};

		window.acpcb.streamStarted = this.streamStarted
		window.acpcb.streamStopped = this.streamStopped

		// window.acpcb.adBlockDetected = () => null

		// window.acpcb.trackChange = () => {
			// if(tracksPlayed % adsMultiplier === 0 && tracksPlayed > 0) {
			// 	//
			// }

			// tracksPlayed++;
		// }

		// window.acpcb.ispotStarted = this.spotStarted
		// window.acpcb.ispotFinished = this.spotFinished

		if(laPlayerInitialized === false) {
			// @ts-ignore
			const { default: canAutoplay } = await import('can-autoplay');
			const { result } = await canAutoplay.audio();

			this.canAutoPlayAudio = result;

			window.acp.init({
				urlCode: this.props.urlCode || this.props.defaultChannel,
				masterStation: this.props.defaultRadioCode,
				debug: 0,
				historyTracks: PLAYLIST_ITEMS_COUNT,
				skipFirstHistoryTrack: false,
			});

			if(this.props.autoplay === false || this.canAutoPlayAudio === false) {
				if(this.props.onStateChange) {
					this.props.onStateChange(PlayerState.Stopped);
				}
				if(this.props.setPlayerState) {
					this.props.setPlayerState(PlayerState.Stopped);
				}
			}
			else {
				if(this.props.noplay !== true) {
					window.acp.streamStart();
				}
				else {
					window.acp.streamStop();
				}
			}
		}
		else {
			const param = {
				'radioCode': this.props.radioCode || this.props.defaultRadioCode,
				'masterStation': this.props.defaultRadioCode,
			};
			if(this.props.noplay !== true) {
				window.acp.switchChannel(param);
			}
		}
	}

	componentWillUnmount()
	{
		window.acp.streamStop();
		window.acpcb = this.oldAcpcb;
		this.oldAcpcb = null;
	}

	componentDidUpdate(prevProps: Props)
	{
		if(prevProps.radioCode !== this.props.radioCode) {
			const param = {
				radioCode: this.props.radioCode,
				masterStation: this.props.defaultRadioCode,
				autoplayOnSwitch: this.props.noplay !== true && this.canAutoPlayAudio || this.props.playerState === PlayerState.Playing,
			};

			window.acp.switchChannel(param);

			if(this.props.onStateChange && this.props.setPlayerState) {
				if(param.autoplayOnSwitch) {
					this.props.onStateChange(PlayerState.Waiting);
					this.props.setPlayerState(PlayerState.Waiting);
				}
				else {
					this.props.onStateChange(PlayerState.Stopped)
					this.props.setPlayerState(PlayerState.Stopped);
				}
			}
		}

		if(prevProps.volume !== this.props.volume) {
			if(this.props.noplay !== true) {
				window.acp.setVolume(this.props.volume);
			}
		}

		if(prevProps.proposedState !== this.props.proposedState) {
			switch (this.props.proposedState) {
				case PlayerState.Playing:
					if(this.props.noplay !== true) {
						window.acp && window.acp.streamStart();
					}
					break;
				case PlayerState.Stopped:
					window.acp && window.acp.streamStop();
					break;
			}
		}

		if(prevProps.noplay !== this.props.noplay) {
			if(this.props.noplay === true) {
				window.acp && window.acp.streamStop();
				this.props.updateMetadata && this.props.updateMetadata({})
				this.props.onHistoryLoaded && this.props.onHistoryLoaded([])
			}
		}
	}

	onInitDone = () =>
	{
		laPlayerInitialized = true;
	}

	onChannelsLoaded = (channels: Channel[]) =>
	{
		if(this.props.onChannelsLoaded)
		{
			this.props.onChannelsLoaded(channels);
		}
	}

	onHistoryLoaded = (history: HistoryItem[]) =>
	{
		if(this.props.onHistoryLoaded /* && this.props.noplay !== true */)
		{
			const historySanitized = history.map(item => ({
				...item,
				songStart: dateToISOString(parsePlaylistDate(item.songStart)),
				songTitle: entities.decodeHTML(item.songTitle || ''),
				songArtist: entities.decodeHTML(item.songArtist || ''),
			}));
			this.props.onHistoryLoaded(historySanitized)
		}
	}

	updateMetadata = (metadata: Metadata) =>
	{
		if(this.props.updateMetadata /* && this.props.noplay !== true */) {
			const { artist, title, album, moderatorName, showName, moderatorMotiveURL, cover, showStart, showEnd, name } = metadata;
			const metadataSanitized = {
				album, moderatorName, showName, moderatorMotiveURL, cover, showStart, showEnd,
				artist: entities.decodeHTML(artist || ''),
				title: entities.decodeHTML(title || ''),
				name: entities.decodeHTML(name || ''),
			};
			this.props.updateMetadata(metadataSanitized);
		}
	}

	// onClickStop = () =>
	// {
	// 	this.props.onStateChange && this.props.onStateChange(PlayerState.Waiting);
	// 	window.acp.streamStop();
	// }

	// onClickPlay = () =>
	// {
	// 	this.props.onStateChange && this.props.onStateChange(PlayerState.Waiting);
	// 	window.acp.streamStart();
	// }

	streamStarted = () =>
	{
		this.canAutoPlayAudio = true;
		this.props.onStateChange && this.props.onStateChange(PlayerState.Playing);
	}

	streamStopped = () =>
	{
		this.props.onStateChange && this.props.onStateChange(PlayerState.Stopped);
	}

	spotStarted = () =>
	{
		this.streamStarted();
	}

	spotFinished = () =>
	{
		this.streamStopped();
	}
}

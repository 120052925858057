import { HistoryItem } from '../../../types/common';
import { FC, ReactNode, SyntheticEvent } from 'react';
import { MediaWrapImgPlay } from '../mediaWrap/MediaWrapImgPlay';

type Props = {
	item: HistoryItem;
	href?: string;
	played?: ReactNode;
	onClick?: (e: SyntheticEvent) => void;
};

export const PlayListItem: FC<Props> = ({ item, href, onClick, played }) => {
	const Anchor = item.yrSongId == '0' || item.yrSongId == null ? 'span' : 'a';
	const anchorProps =
		Anchor === 'a'
			? {
					href,
					target: '_blank',
					rel: 'noopener',
					onClick: onClick,
			  }
			: {
					style: { pointerEvents: 'none' as const },
			  };

	return (
		<Anchor className="b-media-item b-media-item--playlist" {...anchorProps}>
			{item.cover != null && <MediaWrapImgPlay thumbnail={item.cover} />}
			<div className="b-media-item__content">
				{played != null && <p className="b-media-item__played">{played}</p>}
				<p className="b-media-item__title u-text-uppercase">{item.songArtist}</p>
				<p className="b-media-item__meta">{item.songTitle}</p>
			</div>
		</Anchor>
	);
};

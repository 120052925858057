import { FC, ReactNode } from "react";
import { LogoEvropa2 } from "../icons/LogoEvropa2";

type Props = {
	logo?: ReactNode,
}

export const HeaderLogo: FC<Props> = ({ logo }) => {
	return (
		<h1 className="header-logo">
			{logo ? logo : <LogoEvropa2 />}
		</h1>
	)
}

import React, { FC } from "react";
import classNames from 'classnames';

type Props = {
	isSmall?: boolean,
	isActiveUnderline?: boolean,
}

export const TabsNav: FC<Props> = ({ children, isSmall, isActiveUnderline }) => {
	const classes = classNames(
		'm-tabs',
		isSmall ? 'm-tabs--sm' : '',
		isActiveUnderline ? 'm-tabs--underline' : '',
	)

	return (
		<nav className={classes}>
			<ul className="m-tabs__list">
				{
					React.Children.map(children, child => (
						<li className="m-tabs__item">
							{child}
						</li>
					))
				}
			</ul>
		</nav>
	)
}


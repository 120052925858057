// @ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FC, ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<HTMLImageElement>

export const LazyImg: FC<Props> = ({src, ...props}) =>
{
	return (
		<LazyLoadImage
			src={src}
			placeholder={
				<span>
					<noscript>
						<img src={src} {...props} />
					</noscript>
				</span>
			}
			{...props}
			threshold={50}
		/>
	)
}
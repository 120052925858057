import React, { useCallback } from 'react';
import { NowPlaying } from '../ui/player/NowPlaying';
import { Metadata } from '../../types/common';
import { Vote } from '../../types/e2api';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type Props = {
	metadata: Metadata | null;
	radioCode: string;
	voted: Vote | null | undefined;
	onClickVote: (vote: Vote) => void;
};

export const NowPlayingBox = ({ metadata, radioCode, voted, onClickVote }: Props) => {
	const onVote = useCallback(
		(
			radioCode: string | undefined | null,
			artist: string | undefined | null,
			title: string | undefined | null,
			vote: Vote
		) => {
			const data = { radioCode, artist, title, rating: vote };

			fetch(publicRuntimeConfig.env.URL_RATING, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			onClickVote(vote);
		},
		[onClickVote]
	);

	return metadata ? (
		<NowPlaying
			radioCode={radioCode}
			artist={metadata.artist}
			cover={metadata.cover}
			title={metadata.title}
			voted={voted}
			onVote={onVote}
		/>
	) : null;
};

import { Metadata } from '../types/common';
import { RssItem } from '../types/rss';

export const getTitleFromMetadata = (
	metadata: Metadata | null,
	play: string | undefined,
	playRecord: RssItem | undefined,
	playRecordShowName: string | undefined,
	defaultTitle: string
) => {
	return metadata
		? play && playRecord
			? `${playRecord.title} · ${playRecordShowName || ''} – ${metadata.name}`
			: `${metadata.artist} · ${metadata.title} – ${metadata.name}`
		: defaultTitle;
};

import { useState, useCallback } from 'react';

export const useSideMenu = () => {
	const [showChannels, setShowChannels] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	const toggleChannels = useCallback(() => {
		setShowChannels(!showChannels);
		setShowMenu(showMenu && !!showChannels);
	}, [showChannels, showMenu]);

	const openChannels = useCallback(() => {
		setShowChannels(true);
		setShowMenu(false);
	}, []);

	const closeChannels = useCallback(() => {
		setShowChannels(false);
		setShowMenu(showMenu);
	}, [showMenu]);

	const toggleMenu = useCallback(() => {
		setShowChannels(showChannels && !!showMenu);
		setShowMenu(!showMenu);
	}, [showMenu, showChannels]);

	return {
		showChannels,
		setShowChannels,
		showMenu,
		setShowMenu,
		toggleChannels,
		openChannels,
		closeChannels,
		toggleMenu,
	};
};
